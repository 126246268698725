import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityTreeItem } from '@dep/common/interfaces';

import { Windows11ReadyStateValuePipe } from './windows11-ready-state-value.pipe';

@Pipe({
  name: 'clientIsUpgradeableToWindows11',
})
export class ClientIsUpgradeableToWindows11Pipe implements PipeTransform {
  constructor(
    private windows11ReadyStateValuePipe: Windows11ReadyStateValuePipe,
  ) { }

  /**
   * Checks if a given orgaentity is upgradable to Windows 11.
   *
   * @param orgaentity - Orgaentity
   */
  public transform(orgaentity: IOrgaentityTreeItem['item']): boolean {
    const windows11ReadyState = this.windows11ReadyStateValuePipe.transform(orgaentity, 'raw');
    return orgaentity.type === 'CLIENT' && (windows11ReadyState === 'ok' || windows11ReadyState === 'critical');
  }
}
