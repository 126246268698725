import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { RoleRight } from '@dep/common/interfaces';
import { RoleRight as ShopRoleRight } from '@dep/common/shop-api/enums/role-right.enum';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { AdminRootComponent } from '@dep/frontend/pages/admin/admin-root/admin-root.component';
import { UserService } from '@dep/frontend/services/user.service';
import { FocusModeService } from '@dep/frontend/shared/services/focus-mode.service';
import { OrdersService } from '@dep/frontend/shop/services/orders.service';
import { SupportFormsService } from '@dep/frontend/support/services/supportforms.service';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  public menu: { name: string; slug: string; icon?: string; subItems?: { name: string; slug: string; }[]; }[] = [];
  public menuCollapsed = true;
  public isLoading = true;
  public currentSlug?: string;
  public isOrdersIndicatorVisible = false;
  private ordersIndicatorChangedSub$?: Subscription;
  private focusModeSub$?: Subscription;
  public isFocusModeActive = false;
  public isMenuItemExpanded = false;
  public activeSubItemIndex?: number;

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private userService: UserService,
    private supportForms: SupportFormsService,
    private ordersService: OrdersService,
    private focusModeService: FocusModeService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.menu = [];
    const menuItems: Array<{ name: string; slug: string; icon?: string; subItems?: { name: string; slug: string; }[]; }> = [
      { name: 'ADMIN_MENU.HOME', slug: 'home', icon: 'home.svg' },
      { name: 'ADMIN_MENU.CLIENTS', slug: 'clients', icon: 'cockpit.svg' },
      { name: 'ADMIN_MENU.SHOP', slug: 'shop', icon: 'shop.svg' },
      { name: 'ADMIN_MENU.SHOP_ORDERS', slug: 'shop/orders', icon: 'orders.svg' },
      { name: 'ADMIN_MENU.SUPPORTTICKETS', slug: 'supporttickets', icon: 'support.svg' },
      { name: 'ADMIN_MENU.FILES', slug: 'files', icon: 'downloads.svg' },
      { name: 'ADMIN_MENU.DASHBOARD', slug: 'dashboard', icon: 'dashboard.svg' },
      { name: 'ADMIN_MENU.CONTACTS', slug: 'contacts', icon: 'contacts.svg' },
      {
        name: 'ADMIN_MENU.FORMS.MAIN',
        slug: 'forms/instances',
        icon: 'forms.svg',
        subItems: [
          { name: 'ADMIN_MENU.FORMS.MARKET_AGGREGATED_DATA', slug: 'marketAggregatedData' },
          { name: 'ADMIN_MENU.FORMS.MANUAL_MARKET_DATA', slug: 'manualMarketData' },
        ],
      },
      { name: 'ADMIN_MENU.USERS', slug: 'users' },
      { name: 'ADMIN_MENU.LICENSES', slug: 'licenses' },
      { name: 'ADMIN_MENU.PRODUCTTEMPLATES', slug: 'producttemplates' },
      { name: 'ADMIN_MENU.PRODUCTS', slug: 'products' },
      { name: 'ADMIN_MENU.CONFIGURATION', slug: 'configuration' },
      { name: 'ADMIN_MENU.CURATION_PANEL', slug: 'curation-panel' },
    ];

    const configuredRoutes: Routes = this.router.config;
    const adminRoute = configuredRoutes.find((route) => route.component === AdminRootComponent);

    for (const menuItem of menuItems) {
      const menuRouteConfig = adminRoute?.children?.find((route) => route.path === menuItem.slug);

      // check if route config can be found
      if (menuRouteConfig === undefined) {
        continue;
      }

      // check if route config contains RoleRight requirements
      if (
        (typeof menuRouteConfig.data === 'undefined'
        || (typeof menuRouteConfig.data.rights === 'undefined' && typeof menuRouteConfig.data.rightsOr === 'undefined')
        || !Array.isArray(menuRouteConfig.data.rights)
        || !menuRouteConfig.data.rights.length)
        && (!Array.isArray(menuRouteConfig.data?.rightsOr) || !menuRouteConfig.data.rightsOr.length)
      ) {
        this.menu.push(menuItem);
        continue;
      }

      // Check if user meets route config RoleRight requirements.
      // Await in loop is not an issue here because hasRight* uses a cache.
      // eslint-disable-next-line no-await-in-loop
      if (menuRouteConfig.data.rights && await this.userService.hasRightAnd(menuRouteConfig.data.rights as string[])) {
        // If it's a support right check, additionally check if the user sees
        // (has access to) any support forms.
        if (
          menuRouteConfig.data.rights.includes(RoleRight.SUPPORTTICKET_READ)
          // eslint-disable-next-line no-await-in-loop
          && (await this.supportForms.getForms()).length === 0
        ) {
          continue;
        }

        this.menu.push(menuItem);
      }

      // Await in loop is not an issue here because hasRight* uses a cache.
      // eslint-disable-next-line no-await-in-loop
      if (menuRouteConfig.data.rightsOr && await this.userService.hasRightOr(menuRouteConfig.data.rightsOr as string[])) {
        this.menu.push(menuItem);
        continue;
      }
    }

    // Add MAR20X Knowledge Hub (formerly known as "Product Information Platform" and "Web Knowledge Platform") at position 1 (below Home) in the menu array.
    if (await this.userService.hasRight(RoleRight.WEBKNOWLEDGE_READ)) {
      this.menu.splice(1, 0, { name: 'ADMIN_MENU.PRODUCT_INFORMATION_PLATFORM', slug: 'https://app.newroom-connect.com/en-US/mar20x-knowledge-hub/signup/code?code=WQFuv2VhY7PCHQ9DymYV', icon: 'product_information_platform.svg' });
    }

    // Asynchronously (non-blocking) initialize the orders indicator.
    this.initializeOrdersIndicator();

    this.focusModeSub$ = this.focusModeService.focusModeSubject.subscribe((isFocusModeActive: boolean) => {
      this.isFocusModeActive = isFocusModeActive;
    });
    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    this.ordersIndicatorChangedSub$?.unsubscribe();
    this.focusModeSub$?.unsubscribe();
  }

  /**
   * Initially set `isOrdersIndicatorVisible` and subscribe to the related changes.
   */
  private async initializeOrdersIndicator(): Promise<void> {
    this.isOrdersIndicatorVisible = await this.getOrdersIndicatorState();

    // Subscribe to changes in order status and update the orders indicator accordingly.
    this.ordersIndicatorChangedSub$ = this.ordersService.ordersIndicatorChanged$.subscribe(async () => {
      this.isOrdersIndicatorVisible = await this.getOrdersIndicatorState();
    });
  }

  /**
   * Fetch all orders with status `CREATED`. If there is at least one and the user
   * is either a VS1 oder VS2 user, return `true`.
   *
   * @returns `true` if new orders indicator should be displayed
   */
  private async getOrdersIndicatorState(): Promise<boolean> {
    try {
      const isVS1orVS2User = await this.userService.hasRightOr([
        ShopRoleRight.ShopProductCreate, // Right to determine VS1 user.
        ShopRoleRight.ShopProductFromSharedCreate, // Right to determine VS2 user.
      ]);
      if (!isVS1orVS2User) {
        return false;
      }

      const userId = await this.userService.getIdToken();

      const filterQuery = {
        strategy: 'or',
        operator: 'where',
        list: [
          { field: 'status', value: 'CREATED' },
        ],
      };

      const queryParams = {
        userId: userId?.payload['custom:id'],
        filter: filterQuery,
        limiter: { offset: 0, limit: 1 },
        sorting: { field: 'id', direction: 'ASC' },
      };
      const createdOrders = await this.ordersService.getOrders(queryParams);

      return createdOrders.items.length > 0;
    } catch (error) {
      this.logger.error('Getting orders indicator state failed', error);
      return false;
    }
  }

  public setCurrentSlug(slug: string): void {
    this.currentSlug = slug;
  }
}
