import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { UserService } from '@dep/frontend/services/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private router: Router,
    private userService: UserService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.logger.info('Logging out and redirecting to /login');

    this.userService.logout();

    this.router.navigate(['login']);
  }
}
