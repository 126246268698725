import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { environment } from '@dep/frontend/../environments/environment';
import { UserService } from '@dep/frontend/services/user.service';

@Component({
  selector: 'app-licenses-list',
  templateUrl: './licenses-list.component.html',
  styleUrls: ['./licenses-list.component.scss'],
})
export class LicensesListComponent implements OnInit {
  public isLoading = false;
  public lmsListLicensesUrl: SafeUrl;
  private accessToken: any;
  private idToken: any;

  constructor(
    private userService: UserService,
    private sanitizer: DomSanitizer,
  ) {
    this.lmsListLicensesUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.config.lms.url + '/license-list');
  }

  public async ngOnInit(): Promise<void> {
    this.accessToken = await this.userService.getAccessToken();
    this.idToken = await this.userService.getIdToken();
  }

  /**
   * Pass accessToken, idToken and playername to LMS frontend inside iframe
   * */
  public passAuth(): void {
    const iframe = document.getElementById('license-management-server');
    if (!iframe) {
      return;
    }

    const iWindow = (iframe as HTMLIFrameElement).contentWindow;
    if (iWindow) {
      iWindow.postMessage(
        {
          accessToken: this.accessToken,
          idToken: this.idToken,
        },
        environment.config.lms.url + '/license-list',
      );
    }
  }
}
