<div class="contact-details content-block content-block-padding content-block-height">
  <h1 *ngIf="canEdit && contactId === -1">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CREATE_CONTACT' | translate }}</h1>
  <h1 *ngIf="canEdit && contactId !== -1">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.EDIT_CONTACT' | translate }}</h1>
  <h1 *ngIf="!canEdit">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.SHOW_CONTACT' | translate }}</h1>

  <form id="contactForm" #contactForm="ngForm">
    <div *ngIf="isLoading" class="loading-form">
      <div class="loading"></div>
    </div>

    <div class="form-group mt-4" [class.form-row-error]="name.invalid && name.dirty && name.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.NAME' | translate }}*</p>
      <input
        type="text"
        name="name"
        class="form-control form-control-sm"
        [(ngModel)]="model.name"
        #name="ngModel"
        required
        placeholder=""
        [class.no-edit]="!canEdit"
        [disabled]="formDisabled || !canEdit"
      />
    </div>
    <div class="form-group" [class.form-row-error]="name2.invalid && name2.dirty && name2.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.NAME2' | translate }}</p>
      <input
        type="text"
        name="name2"
        class="form-control form-control-sm"
        [(ngModel)]="model.name2"
        #name2="ngModel"
        placeholder=""
        [class.no-edit]="!canEdit"
        [disabled]="formDisabled || !canEdit"
      />
    </div>
    <div class="form-group" [class.form-row-error]="street.invalid && street.dirty && street.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.STREET' | translate }}*</p>
      <input type="text" name="street" class="form-control form-control-sm" [(ngModel)]="model.street" #street="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit" />
    </div>
    <div class="form-group" [class.form-row-error]="city.invalid && city.dirty && city.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CITY' | translate }}*</p>
      <input type="text" name="city" class="form-control form-control-sm" [(ngModel)]="model.city" #city="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit" />
    </div>
    <div class="form-group" [class.form-row-error]="zip.invalid && zip.dirty && zip.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.ZIP' | translate }}*</p>
      <input type="text" name="zip" class="form-control form-control-sm" [(ngModel)]="model.zip" #zip="ngModel" maxlength="10" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit" />
    </div>
    <div class="form-group">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.COUNTRY' | translate }}*</p>
      <div>
        <input [class.hidden]="canEdit" type="text" name="countryDummy" class="form-control form-control-sm no-edit" [value]="model.country" [disabled]="formDisabled || !canEdit" />
        <ng-select
          name="countries"
          [items]="selectCountries"
          [(ngModel)]="selectedCountry"
          (change)="selectedCountryChanged()"
          [disabled]="formDisabled || !canEdit"
          [class.hidden]="!canEdit"
          [clearable]="false"
        ></ng-select>
      </div>
    </div>
    <div class="form-group" [class.form-row-error]="gssn.invalid && gssn.dirty && gssn.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.GSSN' | translate }}*</p>
      <input type="text" name="gssn" class="form-control form-control-sm" [(ngModel)]="model.gssn" #gssn="ngModel" required pattern="[0-9]{7,8}" placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit" />
    </div>
    <div class="form-group" [class.form-row-error]="vatId.invalid && vatId.dirty && vatId.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.VATID' | translate }}*</p>
      <input type="text" name="vatId" class="form-control form-control-sm" [(ngModel)]="model.vatId" #vatId="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit" />
    </div>
    <div class="form-group" [class.form-row-error]="mail.invalid && mail.dirty && mail.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.MAIL' | translate }}*</p>
      <input type="text" name="mail" class="form-control form-control-sm" [(ngModel)]="model.mail" #mail="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit" />
    </div>
    <div class="form-group" [class.form-row-error]="phone.invalid && phone.dirty && phone.touched">
      <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.PHONE' | translate }}*</p>
      <input type="text" name="phone" class="form-control form-control-sm" [(ngModel)]="model.phone" #phone="ngModel" required placeholder="" [class.no-edit]="!canEdit" [disabled]="formDisabled || !canEdit" />
    </div>

    <div class="content-block-controls">
      <div class="row">
        <div class="col-6">
          <button type="button" *ngIf="contactId !== -1" class="btn btn-danger mr-2" (click)="delete()" [disabled]="formDisabled || (contacts && contacts.length < 2) || isLoading"><img class="mb-1" src="assets/icons/delete.svg"> {{ 'ADMIN_PAGES.CONTACTS.DETAILS.DELETE' | translate }}</button>
        </div>
        <div class="col-6 text-right">
          <button type="button" class="btn btn-secondary mr-2" (click)="cancel()" [disabled]="formDisabled">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CANCEL' | translate }}</button>
          <button type="button" class="btn btn-primary" (click)="update()" [disabled]="contactForm.form.invalid || formDisabled">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.SAVE' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-info-modal
  *ngIf="confirmModal"
  [visible]="confirmModal.visible"
  [dialogType]="confirmModal.dialogType"
  [title]="confirmModal.title"
  [content]="confirmModal.content"
  [confirm]="confirmModal.confirm ? confirmModal.confirm.bind(this) : undefined"
  [confirmButtonText]="confirmModal.confirmButtonText || ''"
  [dismiss]="confirmModal.dismiss ? confirmModal.dismiss.bind(this) : undefined"
  [dismissButtonText]="confirmModal.dismissButtonText || ''"
></app-info-modal>
