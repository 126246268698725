import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityClient } from '@dep/common/interfaces';

@Pipe({
  name: 'clientHasLicense',
})
export class ClientHasLicensePipe implements PipeTransform {
  public transform(client: IOrgaentityClient, licenseName: string): boolean {
    return !!(client.licenses?.some((license) => license.name.indexOf(licenseName) === 0));
  }
}
