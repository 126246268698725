import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { IPopup, PopupService } from '@dep/frontend/services/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-product-chooser',
  templateUrl: './product-chooser.component.html',
  styleUrls: ['./product-chooser.component.scss'],
})
export class PopupProductChooserComponent implements OnInit {
  /** Popup information, automatically set by `PopupBaseComponent`. */
  public popup?: IPopup;
  public selectedProduct?: string;
  public productNames: string[] = [];

  constructor(
    private logger: NGXLogger,
    private popupService: PopupService,
  ) { }

  public ngOnInit(): void {
    const productString = this.popup?.settings.customValues?.products ?? '';

    // Filter out the 'ONEMIRROR' if we are in production environment.
    this.productNames = productString.split(',').filter((product) => !(product === 'ONEMIRROR' && environment.stage === 'prod'));
  }

  public onProductClick(product: string): void {
    if (this.selectedProduct !== product) {
      this.selectedProduct = product;
    } else {
      this.selectedProduct = undefined;
    }
  }

  /**
   * Close the popup without submitting a selected product.
   */
  public onPopupClose(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not close popup because "popup" is not set', this.popup);
    }
  }

  /**
   * Send selected product to the callback and close the popup.
   */
  public onProductSubmit(): void {
    if (this.popup && typeof this.popup.callbacks.selectedElement === 'function') {
      this.logger.debug('Triggering callback selectedElement', this.selectedProduct);
      this.popup.callbacks.selectedElement(this.selectedProduct);

      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not trigger callback selectedElement', this.popup);
    }
  }
}
