@if (popup) {
  <div>
    <div class="d-flex mb-4">
      <h3 class="mr-4">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.TITLE' | translate }}</h3>
      <span class="pt-2">
        <img class="mr-2" src="assets/icons/location.svg" alt="location">
        <span style="color: #9F9F9F;">{{ orgaentity?.name }}</span>
      </span>
    </div>
    @if (isLoading) {
      <div class="loading-form-popup">
        <div class="loading"></div>
      </div>
    }

    @if (orgaentity?.level === 'VS2') {
      <div class="mb-3">
        <mat-slide-toggle [(ngModel)]="ignoreBillingAddressWarnings" name="ignoreBillingAddressWarnings">
          <span class="subtitle">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.HIDE_WARNINGS' | translate }}</span>
        </mat-slide-toggle>
      </div>
      <hr class="mb-3">
    }

    @if (billingCoveredBylevel !== undefined) {
      <div class="info-header d-flex align-items-center">
        <img src="assets/icons/info.svg" alt="Information" class="mr-2">
        <div class="ml-1">
          {{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.COSTS_COVERED_BY_NODE' | translate: {
            billingCoveredBylevel: 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.' + billingCoveredBylevel | translate
          } }}
          @for (license of coveredLicenses; track license; let last = $last) {
            <span>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.' + license | translate }}</span>@if (!last) {, } @else {.}
          }
        </div>
      </div>
    }
    @else if (orgaentity && orgaentity.type !== 'LOCATION') {
      <div class="mb-3">
        <mat-slide-toggle [(ngModel)]="isBillingCovered" name="costCover">
          <span class="subtitle">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.NODE_COVERS_COSTS' | translate }}</span>
        </mat-slide-toggle>
      </div>
      <hr>
      <div class="d-flex py-3">
        @for (license of selectableLicenses; track license.title) {
          <div class="mr-3 pr-1" [class.disabled]="!isBillingCovered">
            <label class="license-checkbox">
              <input
                type="checkbox"
                [name]="license.title"
                class="mr-2 mb-1 align-middle"
                [(ngModel)]="license.isChecked"
                (change)="updateIsBillingCovered()"
              >
              <span>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.' + license.title | translate }}</span>
            </label>
          </div>
        }
      </div>
      <hr>
    }
    <form class="mt-3 mb-4" #billingAddressForm="ngForm">
      <div class="form-row">
        <div class="form-group col-md-8" [class.form-row-error]="name.touched && name.invalid">
          <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.NAME' | translate }}*</p>
          <input
            type="text"
            name="name"
            class="form-control"
            required
            placeholder=""
            [(ngModel)]="billingAddress.name"
            #name="ngModel"
          />
        </div>
        <div class="form-group col-md-4" [class.form-row-error]="gssn.touched && gssn.invalid">
          <p class="label">
            {{ 'ADMIN_PAGES.CLIENTS.DETAILS.GSSN' | translate }}*
            <span class="tooltip-custom">
              <img src="assets/icons/info.svg" height="15px" width="15px" class="ml-1" role="button"/>
              <span class="tooltiptext focus-tooltiptext">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.TOOLTIP_GSSN' | translate }}</span>
            </span>
          </p>
          <input
            type="text"
            name="gssn"
            class="form-control"
            required
            placeholder=""
            pattern="[0-9]{7,8}"
            [(ngModel)]="billingAddress.gssn"
            #gssn="ngModel"
          />
        </div>
      </div>
      <hr>
      <div class="form-group mt-4" [class.form-row-error]="street.touched && street.invalid">
        <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.STREET' | translate }}*</p>
        <input
          type="text"
          name="street"
          class="form-control"
          required
          placeholder=""
          [(ngModel)]="billingAddress.street"
          #street="ngModel"
        />
      </div>
      <div class="form-row mt-4">
        <div class="form-group col-md-6" [class.form-row-error]="city.touched && city.invalid">
          <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CITY' | translate }}*</p>
          <input
            type="text"
            name="city"
            class="form-control"
            required
            placeholder=""
            [(ngModel)]="billingAddress.city"
            #city="ngModel"
          />
        </div>
        <div class="form-group col-md-6" [class.form-row-error]="zip.touched && zip.invalid">
          <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ZIP' | translate }}*</p>
          <input
            type="text"
            name="zip"
            class="form-control"
            required
            maxlength="10"
            placeholder=""
            [(ngModel)]="billingAddress.zip"
            #zip="ngModel"
          />
        </div>
      </div>
      <div class="form-row my-4">
        <div class="form-group col-md-6 pb-3">
          <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.COUNTRY' | translate }}*</p>
          <ng-select
            name="countries"
            [clearable]="false"
            [items]="selectCountries"
            [(ngModel)]="billingAddress.country"
            required
          ></ng-select>
        </div>
        <div class="form-group col-md-6" [class.form-row-error]="vatId.touched && vatId.invalid">
          <p class="label">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.VATID' | translate }}*</p>
          <input
            type="text"
            name="vatId"
            class="form-control"
            required
            placeholder=""
            [(ngModel)]="billingAddress.vatId"
            #vatId="ngModel"
          />
        </div>
      </div>
      <hr>
      <div class="form-row mt-4">
        <div class="form-group col-md-6" [class.form-row-error]="email.touched && email.invalid">
          <p class="label">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.EMAIL' | translate }}*</p>
          <input
            name="e_Mail"
            type="email"
            pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+$"
            placeholder=""
            class="form-control"
            required
            [(ngModel)]="billingAddress.email"
            #email="ngModel"
          />
        </div>
        <div class="form-group col-md-6" [class.form-row-error]="phone.touched && phone.invalid">
          <p class="label">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.PHONE' | translate }}*</p>
          <input
            type="text"
            name="phone"
            class="form-control"
            required
            placeholder=""
            [(ngModel)]="billingAddress.phone"
            #phone="ngModel"
          />
        </div>
      </div>
    </form>
    <hr style="margin-left: -44px; margin-right: -44px; margin-bottom: 30px; margin-top: 30px;">
    <div class="text-right mb-4">
      <button type="button" class="btn btn-secondary" (click)="closePopup()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
      <button style="margin-left: 18px" type="submit" class="btn btn-primary" (click)="saveBillingAddress(billingAddressForm)" [disabled]="isLoading">{{ 'ADMIN_PAGES.SAVE' | translate }}</button>
    </div>
  </div>
}
