<div *ngIf="isLoading" class="loading loading-center mt-5"></div>

<iframe
  *ngIf="automatedContentUrl"
  (load)="passAuth()"
  #iframe
  id="curation-panel"
  [src]="automatedContentUrl | safe: 'resourceUrl'"
  scrolling="no"
  width="100%"
  height="100%"
  frameBorder="0"
></iframe>
