<div [class.hidden]="popup.state !== 'OPEN'">
  <div class="popup-backdrop">
    <div class="popup-align" [class.popup-small]="popup.settings.size === popupSize.SMALL">
      <div class="popup-window" @popupWindowTrigger>
        <div class="popup-body">
          <!-- Helper div to get a ViewContainerRef for the popup insertion. -->
          <div #contentSiblingDiv></div>
        </div>
      </div>
    </div>
  </div>
</div>
