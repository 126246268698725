import { Component, OnInit } from '@angular/core';
import { RenewsProduct } from '@dep/common/shop-api/enums/product.enum';
import { NGXLogger } from 'ngx-logger';

import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-product-chooser',
  templateUrl: './product-chooser.component.html',
  styleUrls: ['./product-chooser.component.scss'],
})
export class PopupProductChooserComponent implements OnInit {
  /** Popup information, automatically set by `PopupBaseComponent`. */
  public popup?: IPopup;
  public selectedProduct?: RenewsProduct;
  public products: { name: RenewsProduct; }[] = [];

  constructor(
    private logger: NGXLogger,
    private popupService: PopupService,
  ) { }

  public ngOnInit(): void {
    if (this.popup?.settings.customValues?.products) {
      try {
        this.products = JSON.parse(this.popup.settings.customValues.products);
      } catch (error) {
        this.logger.error('Failed to parse products from popup settings', error);
      }
    }
  }

  public onProductClick(product: RenewsProduct): void {
    if (this.selectedProduct !== product) {
      this.selectedProduct = product;
    } else {
      this.selectedProduct = undefined;
    }
  }

  /**
   * Close the popup without submitting a selected product.
   */
  public onPopupClose(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not close popup because "popup" is not set', this.popup);
    }
  }

  /**
   * Send selected product to the callback and close the popup.
   */
  public onProductSubmit(): void {
    if (this.popup && typeof this.popup.callbacks.selectedProduct === 'function') {
      this.logger.debug('Triggering callback selectedProduct', this.selectedProduct);
      this.popup.callbacks.selectedProduct(this.selectedProduct);

      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not trigger callback selectedProduct', this.popup);
    }
  }
}
