import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify, Hub, HubCapsule } from '@aws-amplify/core';
import { BehaviorSubject } from 'rxjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.stage === 'prod') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

Amplify.configure({
  Auth: {
    region: environment.config.cognito.region,
    userPoolId: environment.config.cognito.userPoolId,
    userPoolWebClientId: environment.config.cognito.userPoolWebClientId,
    oauth: {
      domain: environment.config.oauth.url,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: environment.config.oauth.redirectSignIn,
      redirectSignOut: environment.config.oauth.redirectSignOut,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  API: {
    graphql_endpoint: environment.config.appsync.graphqlEndpoint,
    graphql_endpoint_region: environment.config.appsync.graphqlEndpointRegion,
    graphql_endpoint_iam_region: environment.config.appsync.graphqlEndpointIamRegion,
    graphql_apiKey: environment.config.appsync.graphqlApiKey,
    aws_appsync_authenticationType: environment.config.appsync.awsAppsyncAuthenticationType,
    aws_appsync_graphqlEndpoint: environment.config.appsync.graphqlEndpoint,
  },
  Analytics: {
    disabled: true,
  },
});

// This "hack" is needed because the "auth" event listener needs to be listened to
// as close to `Amplify.configure` as possible, and this is the only way to pass this
// data to an Angular service.
// Injecting a service in `main.ts` does not work, because it would be a separate instance
// (Angular dependency injection is not completely loaded at this point).
(window as any).DEP_AUTH_EVENTS = new BehaviorSubject<HubCapsule | null>(null);

// We have to listen for auth events here to catch all events including SSO errors.
Hub.listen('auth', (data) => {
  // No logger service available yet.
  // eslint-disable-next-line no-console
  console.debug((new Date().toISOString()) + ' DEBUG [webpack:///src/main.ts:54:0] Amplify hub "auth" event', data);

  // Trigger the `Subject`, which is the same object as `AmplifyHubService.authEvents$`.
  (window as any).DEP_AUTH_EVENTS.next(data);
});
