import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { orgaentityBillingAddress } from '@dep/common/core-api/types/orgaentity-billing.type';
import { type Orgaentity, OrgaentityType } from '@dep/common/core-api/types/orgaentity.type';
import { getCountries } from '@dep/common/countries';
import type { IBillingAddress, IOrgaentityAll } from '@dep/common/interfaces';
import { z } from '@dep/common/utils/zod/zod';
import type { NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { type IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-billing-address-popup',
  templateUrl: './billing-address-popup.component.html',
  styleUrl: './billing-address-popup.component.scss',
})
export class PopupBillingAddressComponent implements OnInit {
  public popup?: IPopup;
  public billingAddress: IBillingAddress = this.getEmptyBillingAddress();
  public orgaentity?: IOrgaentityAll;
  public coveredLicenses: string[] = [];
  public selectCountries: NgOption[] = [];
  public ignoreBillingAddressWarnings?: boolean;
  /** `true` if ancestor covers license cost. */
  public isBillingCovered = false;
  public billingCoveredBylevel?: 'COMPANY' | 'MARKET';
  public isLoading = false;

  public readonly selectableLicenses = [
    { title: 'DIGITALSIGNAGE', isChecked: false },
  ];

  constructor(
    private readonly logger: NGXLogger,
    private readonly popupService: PopupService,
    private readonly toastr: ToastrService,
    private readonly ngxTranslate: TranslateService,
    private readonly orgaentitiesService: OrgaentitiesService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    try {
      this.orgaentity = await this.orgaentitiesService.getOrgaentity(Number(this.popup?.settings.customValues?.orgaentityId));
      this.processOrgaentityDataEnhanced(this.orgaentity);

      const ancestorOrgaentities = await this.orgaentitiesService.getAncestorNodes(this.orgaentity.id);

      // Find highest node that overrides the billing address (covers costs).
      const highestAncestorOrgaentity = ancestorOrgaentities.find((oe) => oe.dataEnhanced && typeof oe.dataEnhanced.billingAddressOverrides === 'string');

      if (highestAncestorOrgaentity) {
        this.billingCoveredBylevel = highestAncestorOrgaentity.type === OrgaentityType.Company ? 'COMPANY' : 'MARKET';

        const licenseTitle = highestAncestorOrgaentity.dataEnhanced?.billingAddressOverrides;
        if (typeof licenseTitle === 'string') {
          this.coveredLicenses = JSON.parse(licenseTitle);
        }
      }

      const countriesCodes = Object.entries(getCountries());
      this.selectCountries = countriesCodes.map((c) => ({
        value: c[0],
        label: c[1],
      }));
    } catch (err) {
      this.toastr.error(
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.LOADING_ERROR',
        )),
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.LOADING_ERROR_TITLE',
        )),
      );
      this.logger.error('Something failed while loading or parsing orgaentity', err);
      this.closePopup();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Processes the enhanced data of an orgaentity, including parsing billing address.
   *
   * @param orgaentity - The orgaentity object containing enhanced data
   */
  public processOrgaentityDataEnhanced(orgaentity: IOrgaentityAll): void {
    const data: Record<string, string> = {};
    const dataEnhanced: Record<string, string> = {};

    if (orgaentity.data) {
      for (const kv of orgaentity.data) {
        data[kv.k] = kv.v;
      }
    }
    if (orgaentity.dataEnhanced) {
      for (const kv of orgaentity.dataEnhanced) {
        dataEnhanced[kv.k] = kv.v;
      }
    }

    if (dataEnhanced.billingAddressOverrides) {
      // Check if any license matches the billing address override and mark it as paid.
      for (const license of this.selectableLicenses) {
        if (JSON.parse(String(dataEnhanced.billingAddressOverrides)).includes(license.title)) {
          license.isChecked = true;
        }
      }
    }
    this.updateIsBillingCovered();

    // Parse billing address if it exists.
    if (dataEnhanced.billingAddress) {
      this.billingAddress = JSON.parse(String(dataEnhanced.billingAddress));
    }

    // Set billing address warnings state.
    this.ignoreBillingAddressWarnings = data.ignoreBillingAddressWarnings === 'true';
  }

  public getEmptyBillingAddress(): IBillingAddress {
    return {
      name: '',
      gssn: '',
      street: '',
      city: '',
      zip: '',
      country: '',
      vatId: '',
      email: '',
      phone: '',
    };
  }

  public closePopup(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }

  /**
   * Saves the billing address for the orgaentity.
   */
  public async saveBillingAddress(billingAddressForm: NgForm): Promise<void> {
    // Mark all form controls as touched to trigger validation process.
    Object.values(billingAddressForm.controls).forEach((control) => {
      control.markAsTouched();
    });
    billingAddressForm.form.updateValueAndValidity();

    if (!billingAddressForm.valid) {
      return;
    }

    try {
      this.isLoading = true;

      let billingAddress;
      try {
        billingAddress = orgaentityBillingAddress.parse(this.billingAddress);
      } catch (parseError) {
        if (parseError instanceof z.ZodError) {
          this.logger.error('Billing address invalid', parseError.issues);
        }
        throw parseError;
      }

      const dataEnhanced: Orgaentity['dataEnhanced'] = {
        billingAddress: JSON.stringify(billingAddress),
      };

      // Add billingAddressOverrides to dataEnhanced.
      const checkedLicenses = this.selectableLicenses.filter((license) => license.isChecked).map((license) => license.title);
      if (checkedLicenses.length && this.isBillingCovered) {
        dataEnhanced.billingAddressOverrides = JSON.stringify(checkedLicenses);
      } else {
        dataEnhanced.billingAddressOverrides = undefined;
      }

      if (!this.orgaentity) {
        throw new Error('Orgaentity not set');
      }

      this.logger.debug('Updating orgaentity', this.orgaentity.id);

      await this.orgaentitiesService.updateOrgaentity({
        id: this.orgaentity.id,
        type: this.orgaentity.type,
        data: {
          ...(this.orgaentity.data ? OrgaentitiesService.convertKeyValueObjectArrayToPlainObject(this.orgaentity.data) : {}),
          hasBillingAddress: true,
          ignoreBillingAddressWarnings: this.ignoreBillingAddressWarnings || undefined,
        },
        dataEnhanced: {
          ...(this.orgaentity.dataEnhanced ? OrgaentitiesService.convertKeyValueObjectArrayToPlainObject(this.orgaentity.dataEnhanced) : {}),
          ...dataEnhanced,
        },
      });

      if (this.popup?.callbacks.onBillingAddressSave) {
        this.popup.callbacks.onBillingAddressSave();
      }

      this.isLoading = false;
      this.closePopup();
      this.toastr.success(
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.SAVE_SUCCESS',
        )),
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.SAVE_SUCCESS_TITLE',
        )),
      );
    } catch (err) {
      this.isLoading = false;
      let additionalErrorInformation = '';
      if (err instanceof z.ZodError) {
        additionalErrorInformation = ` <br /><sub>Check fields: ${err.issues[0].path.join(', ')}</sub>`;
      }
      this.toastr.error(
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.SAVE_ERROR',
        )) + additionalErrorInformation,
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.SAVE_ERROR_TITLE',
        )),
      );
      this.logger.error('Saving billing address failed', err);
    }
  }

  /**
   * Update `isBillingCovered` based on the checked state of `selectableLicenses`.
   */
  public updateIsBillingCovered(): void {
    this.isBillingCovered = this.selectableLicenses.some((license) => license.isChecked);
  }
}
