<div class="dashboard-powerbi content-block content-block-padding">
  <h1>{{'ADMIN_PAGES.DASHBOARD.TITLE' | translate }}</h1>
  <div #dashboardView class="w-100" style="height: 96%;" [class.p-2]="isFullScreen">
    <div class="content-block-bar row no-gutters" id="dropDownParentDiv">
      <div class="col-8">
        <div class="row no-gutters">
          <ng-select
            class="col-4 light"
            [items]="userMarkets"
            [(ngModel)]="selectedMarket"
            (change)="onMarketSelectionChange($event.value)"
            [disabled]="isLoading"
            placeholder="Market"
            [clearable]="false"
          ></ng-select>
          <ng-select
            class="col-4 light ml-4"
            [items]="userReports"
            [(ngModel)]="selectedReport"
            (change)="onReportSelectionChange($event.value)"
            [disabled]="isLoading"
            placeholder="Report"
            [clearable]="false"
          ></ng-select>
        </div>
      </div>
      <div class="col-4 text-right mt-1">
        <a class="screen-toggle-icon" (click)="toggleFullScreen()" [class.pr-0]="!isFullScreen">
          <img *ngIf="!isFullScreen" src="assets/icons/enter_full_screen2.svg" alt="Enter full screen" title="{{ 'ADMIN_PAGES.DASHBOARD.ENTER_FULL_SCREEN' | translate }}" class="enter"/>
          <img *ngIf="isFullScreen" src="assets/icons/exit_full_screen.svg" alt="Exit full screen" title="{{ 'ADMIN_PAGES.DASHBOARD.EXIT_FULL_SCREEN' | translate }}" class="exit"/>
        </a>
      </div>
    </div>

    <div *ngIf="isLoading" class="loading loading-center mt-5"></div>
    <div *ngIf="isFailed && !isLoading" class="container pt-5">
      <div class="alert alert-danger" role="alert">
        {{ 'ADMIN_PAGES.DASHBOARD.REPORTING_FAILED' | translate }}
      </div>
    </div>
    <div *ngIf="config?.type === 'dashboard' && !isFailed" #dashboardContainer [class.container-loading]="isLoading" class="dashboard-container" [class.dashboard-container-fullscreen]="isFullScreen"></div>
    <div *ngIf="config?.type === 'report' && !isFailed" #reportContainer [class.container-loading]="isLoading" class="report-container pt-1" [class.report-container-fullscreen]="isFullScreen"></div>
  </div>
</div>
