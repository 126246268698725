import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityAll } from '@dep/common/interfaces';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';

@Pipe({
  name: 'clientIconColor',
})
export class ClientIconColorPipe implements PipeTransform {
  constructor(
    private orgaentitiesService: OrgaentitiesService,
  ) { }

  /**
   * Get player icon color based on DEA_STATUS.
   */
  public transform(item: IOrgaentityAll): 'green' | 'yellow' | 'red' {
    const val = this.orgaentitiesService.getOEDataValue(item, 'DEA_STATUS');
    if (val) {
      if (val.indexOf('ok') !== -1) {
        return 'green';
      }
      if (val.indexOf('warning') !== -1) {
        return 'yellow';
      }
    }
    return 'red';
  }
}
