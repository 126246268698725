import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@dep/frontend/services/config.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  public isMaintenance = false;

  constructor(
    private configService: ConfigService,
  ) { }

  public ngOnInit(): void {
    this.configService.configsObservable$.subscribe({
      next: (data) => {
        if (data.key === 'MAINTENANCE_MODE') {
          this.isMaintenance = data.value === 'true';
        }
      },
    });

    const configs = this.configService.list();
    if (Array.isArray(configs) && configs.length > 0) {
      this.isMaintenance = configs.find((r) => r.key === 'MAINTENANCE_MODE')?.value === 'true';
    }
  }
}
