import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityTreeItem } from '@dep/common/interfaces';

@Pipe({
  name: 'orgaentityData',
})
export class OrgaentityDataPipe implements PipeTransform {
  public transform(data: IOrgaentityTreeItem['item']['data'], key: string): string | undefined {
    if (data) {
      const dataItem = data.find((d) => d.k === key);
      if (dataItem) {
        return dataItem.v;
      }
    }

    return undefined;
  }
}
