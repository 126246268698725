import { Pipe, PipeTransform } from '@angular/core';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';

@Pipe({
  name: 'clientTypeAbbreviation',
})
export class ClientTypeAbbreviationPipe implements PipeTransform {
  constructor(
    private orgaentitiesService: OrgaentitiesService,
  ) { }

  public transform(playername: string): 'os' | 'ot' | 'st' | 'wa' {
    return this.orgaentitiesService.getClientTypeAbbreviation(playername);
  }
}
