import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Params,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  IContact,
  ListResponse,
  OrderByDirection,
} from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import {
  FilterSortMixin,
  FilterSortMixinInterface,
  OrderBy,
} from '@dep/frontend/mixins/filtersort.mixin';
import { PaginationMixin, PaginationMixinInterface } from '@dep/frontend/mixins/pagination.mixin';
import { PopupNewContactComponent } from '@dep/frontend/popups/new-contact-popup/new-contact-popup.component';
import { PopupService, PopupSize } from '@dep/frontend/services/popup.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
})
export class ContactsListComponent extends FilterSortMixin(PaginationMixin(class {}))
  implements OnInit, OnDestroy, PaginationMixinInterface, FilterSortMixinInterface {
  public list: ListResponse<IContact> = { items: [], count: undefined, nextToken: undefined };
  @Input() public embedded = false;
  @Input() public setNumberOfResults?: (amount: number) => void;
  private routeParamsSub$?: Subscription;

  constructor(
    public logger: NGXLogger,
    public router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private popupService: PopupService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) {
    super();

    this.inAdminPanel = typeof route.snapshot.data.inAdminPanel === 'boolean' ? route.snapshot.data.inAdminPanel : true;
    this.logger.debug('Requests list in admin panel', this.inAdminPanel);
  }

  public ngOnInit(): void {
    this.list = {
      items: [],
      count: undefined,
      nextToken: undefined,
    };

    this.logger.debug('Requests list embedded mode', this.embedded);

    this.routeParamsSub$ = this.route.params.subscribe((params) => {
      this.onParamsUpdate(params);
    });
  }

  public ngOnDestroy(): void {
    if (this.routeParamsSub$) {
      this.routeParamsSub$.unsubscribe();
    }
  }

  private onParamsUpdate(params: Params): void {
    this.logger.debug('Setting params', params);

    let nextPage = false;
    let prevPage = false;
    const paramPage = params.page ? Number(params.page) : 1;
    const paramSearchterm: string = params.searchterm ? params.searchterm : '';

    if (this.searchTerm === paramSearchterm) {
      if (this.getPage() + 1 === paramPage) {
        nextPage = true;
      } else if (this.getPage() - 1 === paramPage) {
        prevPage = true;
      }
    }

    this.searchTermInput = paramSearchterm;
    this.searchTerm = paramSearchterm;

    this.orderBy = params.orderby ? params.orderby : OrderBy.DATE;
    this.orderByDirection = params.orderbydirection ? params.orderbydirection : OrderByDirection.DESC;

    this.logger.debug('Going to list page', this.getPage(), paramPage, nextPage, prevPage);
    if (nextPage) {
      this.nextPage();
    } else if (prevPage) {
      this.previousPage();
    } else {
      this.setPage(paramPage);
    }
  }

  public updateNavParams(page?: number): void {
    const nav: any[] = [];
    if (this.inAdminPanel) {
      nav.push('/contacts');
    } else {
      nav.push('/orders');
    }

    nav.push({
      page: page || this.getPage(),
      searchterm: this.searchTerm,
      orderby: this.orderBy,
      orderbydirection: this.orderByDirection,
    });
    this.router.navigate(nav);
  }

  /**
   * Set/append requests.
   *
   * @param limit - Maximum amount of returned requests
   * @returns Promise with boolean indicating whether Pagination should go to next page or not
   */
  public async loadItems(start: number, limit: number): Promise<ListResponse<IContact>> {
    this.logger.debug(`Loading ${limit} more contacts (start: ${start})...`);
    if (typeof this.searchTerm === 'string') {
      this.logger.debug('Search term applied', this.searchTerm);
    }

    return this.usersService.getContacts(start, limit, typeof this.searchTerm === 'string' ? this.searchTerm.trim() : undefined)
      .then((requests) => {
        if (this.setNumberOfResults) {
          this.setNumberOfResults(requests.count || 0);
        }
        return requests;
      });
  }

  public showNewContactPopup(): void {
    this.popupService.open(
      PopupNewContactComponent,
      {
        size: PopupSize.SMALL,
      },
      {
        onSave: (formContents: IContact, uuid: string) => this.onNewContactPopupSave(formContents, uuid),
      },
    );
  }

  public async onNewContactPopupSave(formContents: IContact, uuid: string): Promise<void> {
    try {
      const response = await this.usersService.addContact(
        formContents,
      );
      this.logger.debug('Added a new contact', formContents, response);
      this.popupService.close(uuid);
      this.toastr.success(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.SAVE_SUCCESS')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.SAVE_SUCCESS_TITLE')),
      );
      this.resetList();
      this.updateNavParams(1);
    } catch (error) {
      this.logger.error('Adding new contact failed', error);

      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.SAVE_ERROR')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.SAVE_ERROR_TITLE')),
      );
    }
  }
}
