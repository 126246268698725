import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

import packageInfo from '../../../package.json';

import { PopupGenericMessageComponent } from '@dep/frontend/popups/generic-message/generic-message.component';
import { getLanguageNameByLanguageCode, getLanguageCodeByLanguageName, languages } from '@dep/frontend/services/countries';
import { PopupService, PopupSize } from '@dep/frontend/services/popup.service';
import { TranslationService } from '@dep/frontend/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public readonly version = packageInfo.version;
  public readonly stageSuffix = (environment.stage === 'prod') ? '' : '-' + environment.stage;
  public language = '';
  public allLanguages: { name: string; icon: string; }[] = [];

  constructor(
    private logger: NGXLogger,
    private translationService: TranslationService,
    private ngxTranslate: TranslateService,
    private http: HttpClient,
    private popupService: PopupService,
  ) {
    this.ngxTranslate.onLangChange.subscribe(() => {
      this.language = this.translationService.getLanguage();
    });

    this.allLanguages = [];
    for (const langCode in languages) {
      if (languages.hasOwnProperty(langCode)) {
        this.allLanguages.push(languages[langCode]);
      }
    }
  }

  public showProviderModal(): void {
    // Currently, only the english version of provider information is supported.
    const language = 'en';

    this.http.get('/assets/i18n/provider.' + language + '.html', { responseType: 'text' }).subscribe(
      (data) => {
        this.popupService.open(
          PopupGenericMessageComponent,
          {
            size: PopupSize.LARGE,
            closeable: true,
            customValues: {
              title: this.ngxTranslate.instant('PROVIDER.PROVIDER'),
              message: this.wrapModalContent(data),
            },
          },
          {
            okButton: () => { },
          },
        );
      },
    );
  }

  public showLegalModal(): void {
    // Currently, only the english version of legal notice is supported.
    const language = 'en';

    this.http.get('/assets/i18n/legalnotice.' + language + '.html', { responseType: 'text' }).subscribe(
      (data) => {
        this.popupService.open(
          PopupGenericMessageComponent,
          {
            size: PopupSize.LARGE,
            closeable: true,
            customValues: {
              title: this.ngxTranslate.instant('LEGAL.LEGAL_NOTICE'),
              message: this.wrapModalContent(data),
            },
          },
          {
            okButton: () => { },
          },
        );
      },
    );
  }

  public showPrivacyModal(): void {
    // Currently, only the english version of privacy policy is supported.
    const language = 'en';

    this.http.get('/assets/i18n/privacy.' + language + '.html', { responseType: 'text' }).subscribe(
      (data) => {
        this.popupService.open(
          PopupGenericMessageComponent,
          {
            size: PopupSize.LARGE,
            closeable: true,
            customValues: {
              title: this.ngxTranslate.instant('PRIVACY.PRIVACY'),
              message: this.wrapModalContent(data),
            },
          },
          {
            okButton: () => { },
          },
        );
      },
    );
  }

  private wrapModalContent(content: string) {
    return '<div class="legal-page">' + content + '</div>';
  }

  public getLanguageName(languageCode: string): string | undefined {
    return getLanguageNameByLanguageCode(languageCode);
  }

  public changeLanguage(langName: string): void {
    const lang = getLanguageCodeByLanguageName(langName);
    this.logger.debug('Changing language from footer', lang);
    if (lang) {
      this.translationService.changeLanguage(lang, true);
    }
  }
}
