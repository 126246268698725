import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityAll, IOrgaentityClient } from '@dep/common/interfaces';

import { OrgaentitiesService } from '../services/orgaentities.service';

@Pipe({
  name: 'windows11ReadyStateValue',
})
export class Windows11ReadyStateValuePipe implements PipeTransform {
  /** Mapping from Windows 11 ready state to attribute values. */
  private readonly ATTRIBUTE_MAPPING = {
    upgraded: {
      raw: 'upgraded',
      icon: 'windows11_upgraded',
      tooltipKey: 'WINDOWS11_UPGRADED',
    },
    ok: {
      raw: 'ok',
      icon: 'windows11_available',
      tooltipKey: 'WINDOWS11_CAN_UPGRADE',
    },
    critical: {
      raw: 'critical',
      icon: 'windows11_unavailable',
      tooltipKey: 'WINDOWS11_UPGRADE_NEW_HARDWARE',
    },
    unknown: {
      raw: 'unknown',
      icon: 'windows11_unknown',
      tooltipKey: 'WINDOWS11_CAN_NOT_UPGRADE',
    },
  };

  constructor(
    private orgaentitiesService: OrgaentitiesService,
  ) { }

  /**
   * Get a Windows 11 migration status value of an orgaentity.
   *
   * Possible `attribute`s:
   * - `raw`: Raw value (= mapping key)
   * - `icon`: Icon file name without file extension
   * - `tooltipKey`: Tooltip text translation key suffix
   *
   * @param orgaentity - Orgaentity
   * @returns Value of the requested attribute
   */
  public transform(
    orgaentity: IOrgaentityAll,
    attribute: 'raw' | 'icon' | 'tooltipKey',
  ): string {
    // Check if the operating system is already upgraded to Windows 11.
    if ((orgaentity as IOrgaentityClient).operatingSystem && (orgaentity as IOrgaentityClient).operatingSystem?.indexOf('Windows 11') !== -1) {
      return this.ATTRIBUTE_MAPPING.upgraded[attribute];
    }

    // Get Windows 11 ready state from orgaentity data.
    const val = this.orgaentitiesService.getOEDataValue(orgaentity, 'DEA_WIN11_READY') ?? '';

    // Depending on the state, return the corresponding attribute value from the mapping (`ATTRIBUTE_MAPPING`).
    if (val.startsWith('ok') || val.startsWith('warning')) {
      return this.ATTRIBUTE_MAPPING.ok[attribute];
    }
    if (val.startsWith('critical')) {
      return this.ATTRIBUTE_MAPPING.critical[attribute];
    }

    return this.ATTRIBUTE_MAPPING.unknown[attribute];
  }
}
