import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  type IDeviceMetricItem,
  type IOrgaentityTreeItem,
} from '@dep/common/interfaces';

import { ClientMetricValuePipe } from './client-metric-value.pipe';
import { ClientOnlineStatusPipe } from './client-online-status.pipe';

@Pipe({
  name: 'clientMetricColor',
})
export class ClientMetricColorPipe implements PipeTransform {
  constructor(
    private readonly clientOnlineStatusPipe: ClientOnlineStatusPipe,
    private readonly clientMetricValuePipe: ClientMetricValuePipe,
  ) { }

  /**
   * Gets Metrics icon color. If Connection status is red, the icon color is always grey for Icinga metrics.
   *
   * @param metricName Metric name. e.g. OS, RocketStatus, etc.
   * @returns Metrics icon's color (for available colors, see `getOnDemandMetricInfo()`)
   */
  public transform(row: IOrgaentityTreeItem, metricName: string, metrics?: IDeviceMetricItem[]): string {
    // If the player is not available, we cannot display a real red or green state for all Icinga metrics.
    // Reason: When a player is disconnected, the IP could change which would lead to false-positive statuses.
    if (!['RCPSData', 'RocketStatus', 'ShowroomPresenterData'].includes(metricName) && this.clientOnlineStatusPipe.transform(metrics).status === 'red') {
      return 'grey';
    }
    return this.clientMetricValuePipe.transform(row, metricName, metrics).status;
  }
}
