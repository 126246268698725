import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityAll } from '@dep/common/interfaces';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';

@Pipe({
  name: 'clientIsIOTAssigned',
})
export class ClientIsIOTAssignedPipe implements PipeTransform {
  constructor(
    private orgaentitiesService: OrgaentitiesService,
  ) { }

  /**
   * Check if `DEA_IOT` is assigned on a player.
   *
   * @param includeOffline If true, the pipe will return `true` also if the player is "Disconnected"
   */
  public transform(item: IOrgaentityAll, includeOffline = false): boolean {
    const value = this.orgaentitiesService.getOEDataValue(item, 'DEA_IOT');
    if (
      value && (
        value.indexOf('Connected') === 0
        || (includeOffline && value.indexOf('Disconnected') === 0)
      )
    ) {
      return true;
    }
    return false;
  }
}
