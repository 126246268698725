import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import {
  IPopup,
  PopupService,
  PopupSize,
  PopupState,
} from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-base[popup]',
  templateUrl: './popup-base.component.html',
  styleUrls: ['./popup-base.component.scss'],
  animations: [
    trigger('popupWindowTrigger', [
      transition(':enter', [
        style({ opacity: 0, scale: 0.9 }),
        animate('100ms', style({ opacity: 1, scale: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, scale: 0.5 })), // No effect, because it is nested inside the *ngIf which removes the whole popup-base.
      ]),
    ]),
  ],
})
export class PopupBaseComponent implements AfterViewInit {
  public readonly popupSize = PopupSize;
  @Input()
  public popup!: IPopup;

  /**
   * This dummy div is used to dynamically insert the respective popup content component as a sibling element.
   * See `ngAfterViewInit()` and `PopupService.addDynamicComponent()`.
   */
  @ViewChild('contentSiblingDiv', { read: ViewContainerRef })
  public contentSiblingDiv?: ViewContainerRef;

  constructor(
    private logger: NGXLogger,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private popupService: PopupService,
  ) { }

  public ngAfterViewInit(): void {
    if (!this.contentSiblingDiv) {
      this.logger.warn('PopupBaseComponent: contentSiblingDiv not set', this.contentSiblingDiv);
    } else {
      this.setContentSiblingDiv(this.contentSiblingDiv);
    }
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Set the `ViewContainerRef` of the popup content sibling div where the popup content
   * component (e. g. a widget view) should be inserted. Then add the dynamic content.
   *
   * @param viewContainerRef Popup content sibling element
   */
  private setContentSiblingDiv(viewContainerRef: ViewContainerRef): void {
    const popup = this.popupService.getPopupByUuid(this.popup.uuid);

    popup.contentSiblingDiv = viewContainerRef;

    // Since we have the required div, the popup can finally be opened.
    this.addDynamicComponent(popup);
  }

  /**
   * Add an Angular component dynamically as a sibling of `popupViewContainer`.
   * `setPopupViewContainerRef()` has to be called before.
   * Based on https://medium.com/front-end-weekly/dynamically-add-components-to-the-dom-with-angular-71b0cb535286
   */
  private addDynamicComponent(popup: IPopup, data?: { [key: string]: any }): void {
    this.logger.debug('PopupBaseComponent: Adding dynamic component for popup', popup);
    if (!popup.contentSiblingDiv) {
      this.logger.error('PopupBaseComponent: No contentSiblingDiv');
      throw new Error('No contentSiblingDiv');
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const componentInstance = this.viewContainerRef.createComponent<any>(popup.contentComponent);

    // Pass the popup information to the content instance, so that it knows its UUID for example.
    componentInstance.instance.popup = popup;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        componentInstance.instance[key] = data[key];
      }
    }
    popup.contentSiblingDiv.insert(componentInstance.hostView);
    this.logger.debug('PopupBaseComponent: Inserting', componentInstance.hostView, 'into', popup.contentSiblingDiv);

    this.popupService.setPopupState(popup.uuid, PopupState.OPEN);
  }
}
