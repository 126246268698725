@if (popup && popup.settings.customValues) {
  <form #energyManagementForm="ngForm">
    <div class="position-relative">
      <!-- Header -->
      <div class="modal-header p-0 border-0">
        <div class="modal-title row align-items-center">
          <h2 class="col">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.TITLE' | translate}}</h2>
          @if (popup.settings.customValues.type === 'CLIENT') {
            <div>
              <img
                src="assets/icons/client_{{ popup.settings.customValues.clientAbbreviation }}_{{ popup.settings.customValues.clientColor }}.svg"
                class="client-icon"
                [title]="'ID: ' + popup.settings.customValues.orgaentityId"
              />
              <span style="color: #9F9F9F;" class="ml-1 mr-2">{{ popup.settings.customValues.playername }}</span>
              @if (isEnergySettingsPending) {
                <span class="badge badge-warning">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.PENDING' | translate }}</span>
              }
              @if (!isSuccessSaved.energySettings) {
                <span class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
              }
            </div>
          }
          @if (popup.settings.customValues.type === 'LOCATION') {
            <div class="mt-1 px-2">
              <span style="color: #9F9F9F;">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOCATION' | translate }}: {{ popup.settings.customValues.name }}</span>
            </div>
          }
        </div>
      </div>
      <!-- Energy Settings Container -->
      <div class="py-2">
        @if (popup.settings.customValues.type === 'CLIENT') {
          <div class="row mb-4 align-items-baseline">
            <div class="col-md-4 mt-2">
              <mat-slide-toggle [(ngModel)]="isActiveHours" name="activeHours">
                <span class="container-title">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ACTIVITY_PERIOD' | translate }}</span>
              </mat-slide-toggle>
              <span
                class="ml-2"
                matTooltip="{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.INFO' | translate }}"
              >
                <img src="assets/icons/info.svg" alt="information">
              </span>
            </div>
            @if (isActiveHours) {
              <div class="col-md-8 pr-3 text-md-right">
                <img class="mr-1 mb-1" src="assets/icons/undo.svg" alt="reverse">
                <a (click)="initEnergySettings()" class="primary">
                  {{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.UNDO' | translate }}
                </a>
              </div>
            }
          </div>
        }
        @if (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.PlayersSelection) {
          <div class="mt-2 d-flex flex-column" style="width:80%;">
            <h4> {{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.PLAYER_SELECTION' | translate }}: </h4>
            <ul class="pl-0 mt-2">
              <li class="row no-gutters" (click)="selectAllClients()">
                <input type="checkbox" #selectAllPlayers name="selectAllPlayers" [(ngModel)]="areAllClientsSelected">
                <label class="ml-4 mb-0 font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.ALL_PLAYERS' | translate }}</label>
              </li>
              @for (client of clients; track client.client.id) {
                @if (client.client | clientIsIOTAssigned: true) {
                  <li (click)="selectClient(client)" class="row no-gutters">
                    <input type="checkbox" name="{{client.client.name}}" [value]="client.client.id" [checked]="client.selected">
                    <span class="row ml-1">
                      <div style="width: 18px;">
                        @if (client.client |  clientIsEnergyManagementEnabled) {
                          <img src="assets/icons/energy_conservation.png" class="client-icon-leaf mx-1 mt-1"/>
                        }
                      </div>
                      <span class="mx-1">
                        <img src="assets/icons/client_{{ client.client.playername | clientTypeAbbreviation }}_{{ client.client | clientIconColor }}.svg" class="client-icon"/>
                      </span>
                      <label class="form-check-label mb-0 font-weight-normal" for="flexCheckDefault">{{ client.client.name }}</label>
                    </span>
                  </li>
                }
              }
            </ul>
            <div class="mb-3 d-flex align-items-center">
              <img src="assets/icons/info.svg" alt="information">
              <div class="ml-2" [innerHTML]="'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.INFO_LEAF_ICON' | translate"></div>
            </div>
          </div>
        }
        @if (
          (popup.settings.customValues.type === 'CLIENT' && isActiveHours)
          || (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings)
        ) {
          <div>
            <div class="col-12 d-flex flex-wrap p-0">
              @for (row of activeHoursSelectValue; let i = $index; track i) {
                <div  class="mr-3 mb-1 activity-window-container">
                  <div class="activity-window-header">
                    <div class="font-weight-bold">
                      {{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_ACTIVITY_CYCLE' | translate }}{{' ' + (i + 1)}}
                    </div>
                    <!-- Remove icon -->
                    <div>
                      @if (activeHoursSelectValue.length > 0) {
                        <a class="img-wrapper" href="javascript: void(0)" (click)="removeTimeSelectionRow(row)">
                          <img src="assets/icons/delete.svg" alt="Remove icon"/>
                        </a>
                      }
                    </div>
                  </div>

                  <!-- Weekday picker -->
                  <div class="border-top-bottom">
                    <div class="weekday-picker mb-4 ml-2">
                      <input type="checkbox" id="weekday-mon-{{i}}" value="Monday" name="monday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days | includes: ('Monday')" [disabled]="weekdayConfigured(row, 'Monday')">
                      <label for="weekday-mon-{{i}}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.MONDAY' | translate }}</label>
                      <input type="checkbox" id="weekday-tue-{{i}}" value="Tuesday" name="tuesday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days | includes: ('Tuesday')" [disabled]="weekdayConfigured(row, 'Tuesday')">
                      <label for="weekday-tue-{{i}}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.TUESDAY' | translate }}</label>
                      <input type="checkbox" id="weekday-wed-{{i}}" value="Wednesday" name="wednesday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days | includes: ('Wednesday')" [disabled]="weekdayConfigured(row, 'Wednesday')">
                      <label for="weekday-wed-{{i}}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.WEDNESDAY' | translate }}</label>
                      <input type="checkbox" id="weekday-thu-{{i}}" value="Thursday" name="thursday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days | includes: ('Thursday')" [disabled]="weekdayConfigured(row, 'Thursday')">
                      <label for="weekday-thu-{{i}}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.THURSDAY' | translate }}</label>
                      <input type="checkbox" id="weekday-fri-{{i}}" value="Friday" name="friday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days | includes: ('Friday')" [disabled]="weekdayConfigured(row, 'Friday')">
                      <label for="weekday-fri-{{i}}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.FRIDAY' | translate }}</label>
                      <input type="checkbox" id="weekday-sat-{{i}}" value="Saturday" name="saturday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days | includes: ('Saturday')" [disabled]="weekdayConfigured(row, 'Saturday')">
                      <label for="weekday-sat-{{i}}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SATURDAY' | translate }}</label>
                      <input type="checkbox" id="weekday-sun-{{i}}" value="Sunday" name="sunday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days | includes: ('Sunday')" [disabled]="weekdayConfigured(row, 'Sunday')">
                      <label for="weekday-sun-{{i}}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SUNDAY' | translate }}</label>
                    </div>
                  </div>

                  <!-- Time range slider -->
                  <div class="col-12">
                    <app-time-range-slider
                      [(startTime)]="row.start"
                      [(endTime)]="row.end"
                    ></app-time-range-slider>
                  </div>
                </div>
              }
              <!-- Add item text -->
              @if (weekDaysSelected.length < 7) {
                <div class="activity-window-empty my-1 mb-1">
                  <button type="button" (click)="addTimeSelectionRow()" class="btn btn-link p-0">
                    <div class="plus-icon">
                      <img src="assets/icons/plus_white.svg" alt="plus">
                    </div>
                  </button>
                </div>
              }
            </div>
          </div>
        }

        @if (isLoading.energySettings || hasError.energySettings || isLoading.clients) {
          <div class="overlay">
            @if (isLoading.energySettings || isLoading.clients) {
              <div class="loading"></div>
            }
            @if (hasError.energySettings) {
              <div class="text-danger font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
            }
          </div>
        }
      </div>
      <!-- Content Throttling Container -->
      @if (
        popup.settings.customValues.type === 'CLIENT'
        || (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings)
      ) {
        <div class="py-4">
          <div>
            <mat-slide-toggle [(ngModel)]="isContentThrottling" name="contentThrottling" [disabled]="isContentThrottling === undefined">
              @if (popup.settings.customValues.type === 'CLIENT') {
                <span class="container-title">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING' | translate }}</span>
              }
              @if (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings) {
                <span class="font-weight-lighter">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_ALL' | translate }}</span>
              }
            </mat-slide-toggle>
            <span
              class="ml-2"
              matTooltip="{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_INFO' | translate }}"
            >
              <img src="assets/icons/info.svg" alt="information">
            </span>
            @if (!isSuccessSaved.throttling) {
              <span class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
            }
          </div>
          @if (isLoading.throttling || hasError.throttling) {
            <div class="overlay">
              @if (isLoading.throttling) {
                <div class="loading"></div>
              }
              @if (hasError.throttling) {
                <div class="text-danger" style="opacity: 1">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
              }
            </div>
          }
        </div>
      }
    </div>
    <hr class="break-line">
    <!-- Button Container -->
    @if (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.PlayersSelection) {
      <div class="modal-footer border-0 p-0 pt-1">
        <button type="button" class="btn btn-secondary" (click)="closePopup()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
        <button
          type="button"
          (click)="proceedToEnergySettingsStep()"
          [disabled]="isNextButtonDisabled"
          class="btn btn-primary"
        >{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.CONTINUE' | translate }}</button>
      </div>
    }
    @if (
      popup.settings.customValues.type === 'CLIENT'
      || (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings)
    ) {
      <div class="d-flex justify-content-end p-3">
        <button type="button" class="btn btn-secondary" (click)="closePopup()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
        <button
          type="submit"
          (click)="saveData(energyManagementForm?.form)"
          [disabled]="isLoading.throttling || isLoading.energySettings || hasError.throttling || hasError.energySettings || (isActiveHours && (isFormDisabled || !energyManagementForm.valid || reqInputIncomplete()))"
          class="btn btn-primary ml-3"
        >{{ 'ADMIN_PAGES.SAVE' | translate }}</button>
      </div>
    }
  </form>
}
