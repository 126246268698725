import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityTreeItem } from '@dep/common/interfaces';
import { IForm } from '@dep/common/interfaces/support';

@Pipe({
  name: 'clientHasSupportTicketForm',
})
export class ClientHasSupportTicketFormPipe implements PipeTransform {
  /**
   * Checks product specific form access for the user
   * CarShow Adapter    -> form id 1
   * Digital Signage    -> form id 2
   * Matchmaker         -> form id 3
   * Handover Companion -> form id 6
   * CARmedia Stick     -> form id 7
   * Showroom:Connect   -> form id 9
   *
   * @param item specific product IOrgaentityTreeItem
   * @return True or false
   */
  public transform(item: IOrgaentityTreeItem['item'], supportForms: IForm[]): boolean {
    switch (item.type) {
      case 'CARSHOWADAPTER':
        return supportForms.some((form) => form.id === 1);
      case 'CLIENT':
        return supportForms.some((form) => form.id === 2);
      case 'MATCHMAKER':
        return supportForms.some((form) => form.id === 3);
      case 'HANDOVERCOMPANION':
        return supportForms.some((form) => form.id === 6);
      case 'CARMEDIASTICK':
        return supportForms.some((form) => form.id === 7);
      case 'SHOWROOMCONNECT':
        return supportForms.some((form) => form.id === 9);
      default:
        break;
    }
    return false;
  }
}
