import { Directive } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Directive({
  selector: '[appProvideParentForm]',
  providers: [
    {
      provide: ControlContainer,
      useFactory: (form: NgForm) => form,
      deps: [NgForm],
    },
  ],
})
export class ProvideParentFormDirective {}
