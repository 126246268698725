import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityAll, RoleRight } from '@dep/common/interfaces';
import { RoleRight as ShopRoleRight } from '@dep/common/shop-api/enums/role-right.enum';
import { NGXLogger } from 'ngx-logger';

import { UserService } from '@dep/frontend/services/user.service';

// TODO: Somehow make it possible to have a rights check pipe without the impure `| async` pipe.
@Pipe({
  name: 'userHasRight',
})
export class UserHasRightPipe implements PipeTransform {
  constructor(
    public logger: NGXLogger,
    private userService: UserService,
  ) { }

  public transform(right: RoleRight | ShopRoleRight, orgaentity?: IOrgaentityAll, path?: string): Promise<boolean> {
    this.logger.trace('Checking right', right, 'to orgaentity', orgaentity);

    if (orgaentity) {
      return this.userService.hasRightOnPath(right, orgaentity.path);
    }

    if (path) {
      return this.userService.hasRightOnPath(right, path);
    }

    return this.userService.hasRight(right);
  }
}
