import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CookieService, DataProcessingServiceCookie, DataProcessingServiceName } from '@dep/frontend/services/cookie.service';
import { UserService } from '@dep/frontend/services/user.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {
  /** If set to `false`, the banner is never shown in the first place. */
  public showBannerInitially = false;
  /** If set to `false`, the banner will be hidden, including CSS animations. */
  public showBanner = true;
  private userUUIDv4?: string;
  private routerSub$?: Subscription;
  private sessionSub$?: Subscription;

  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.init();

    this.sessionSub$ = this.userService.sessionObservable$.subscribe({
      next: () => {
        this.init();
      },
    });
  }

  public ngOnDestroy(): void {
    this.sessionSub$?.unsubscribe();
    this.routerSub$?.unsubscribe();
  }

  private init(): void {
    if (this.userService.getCurrentUsername()) {
      this.checkCookieAndUpdateView();

      this.routerSub$ = this.router.events.subscribe({
        next: (event) => {
          if (event instanceof NavigationEnd) {
            this.checkCookieAndUpdateView();
          }
        },
      });
    }
  }

  private async checkCookieAndUpdateView(): Promise<void> {
    this.userUUIDv4 = await this.userService.getCurrentSub() ?? undefined;
    if (this.cookieService.check(DataProcessingServiceCookie.BANNER + this.userUUIDv4)) {
      this.showBannerInitially = false;
    } else {
      this.showBannerInitially = true;
    }
  }

  public hideBanner(): void {
    this.showBanner = false;
    if (this.cookieService.isCookieAllowed(DataProcessingServiceName.BANNER)) {
      this.cookieService.set(DataProcessingServiceCookie.BANNER + this.userUUIDv4, 'x', 7, undefined, undefined, true, 'Strict');
    }
  }
}
