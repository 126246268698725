import { Component, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { UserTokenService } from '@dep/frontend/services/user-token.service';
import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-curation-panel',
  templateUrl: './curation-panel.component.html',
  styleUrls: ['./curation-panel.component.scss'],
})
export class CurationPanelComponent implements OnDestroy {
  public isLoading = false;
  public automatedContentUrl = environment.config.automatedContent.url;
  private accessToken: any;
  private idToken: any;
  private timeout?: number;

  constructor(
    private userService: UserService,
    private userTokenService: UserTokenService,
    private logger: NGXLogger,
  ) { }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  private async nextSend(win: Window): Promise<void> {
    await this.sendToken(win);
    this.timeout = window.setTimeout(async () => {
      await this.nextSend(win);
    }, this.userTokenService.getExpirationTimeLeft() + 1000);
  }

  public passAuth(): void {
    if (this.automatedContentUrl) {
      const iframe = document.getElementById('curation-panel');
      if (iframe === null) {
        return;
      }
      const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
      if (iWindow) {
        this.nextSend(iWindow);
      }
      iframe.style.height = '2000px';
    }
  }

  private async sendToken(win: Window): Promise<void> {
    this.logger.debug('Sending token to Automated Content');
    this.accessToken = await this.userService.getAccessToken();
    this.idToken = await this.userService.getIdToken();

    win.postMessage(
      {
        accessToken: this.accessToken,
        idToken: this.idToken,
      },
      this.automatedContentUrl,
    );
  }
}
