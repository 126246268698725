<nav class="d-flex navigation-bar align-items-end user-select-none">
  <span class="mr-3 mr-sm-5 mb-1">
    <img src="assets/icons/header_logo.png" srcset="assets/icons/header_logo@2x.png 2x" alt="Logo" />
  </span>
  <span class="title">{{ 'HEADER.TITLE' | translate }}</span>
  <a *ngIf="isLoggedIn" class="dropdown text-right ml-auto mb-1 d-print-none" [routerLink]="[]">
    <img class="mr-2 mb-1" src="assets/icons/user.svg">
    <span class="username d-none d-md-inline-block mr-2">{{ username }}</span>
    <img class="mb-1" src="assets/icons/arrow_down.svg">
    <div class="dropdown-content">
      <a [routerLink]="[]" (click)="openRolesManager()"><img class="mr-2 align-text-top" src="assets/icons/roles.svg" alt="My Roles">{{ 'HEADER.MY_ROLES' | translate }}</a>
      <a [routerLink]="[]" (click)="logout()"><img class="ml-1 mr-2 align-text-top" src="assets/icons/logout.svg" alt="Logout">{{ 'HEADER.LOGOUT' | translate }}</a>
    </div>
  </a>
</nav>

<!-- TODO: Will be enabled again when a new banner is provided. (Disabled in March 2023) -->
<!-- <app-banner *ngIf="isLoggedIn"></app-banner> -->
