import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { RcpsPlayerPropertiesInput } from '@dep/common/device-api/types/rcps-player-properties-input.type';
import {
  IDeviceMetricItem,
  IOrgaentityClient,
  IOrgaentityTreeItem,
  RoleRight,
} from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';

import { getPlaylistInfo } from '@dep/frontend/model-playlist-mapping';
import { ClientIconColorPipe } from '@dep/frontend/pipes/client-icon-color.pipe';
import { UserHasRightPipe } from '@dep/frontend/pipes/user-has-right.pipe';
import { PopupRcpsContentEditComponent } from '@dep/frontend/popups/rcps-content-edit/rcps-content-edit.component';
import { PopupRCPSSettingsComponent } from '@dep/frontend/popups/rcps-settings/rcps-settings.component';
import { DeviceSettingsService } from '@dep/frontend/services/device-settings.service';
import { OrgaentitiesService, OneMirrorLicense } from '@dep/frontend/services/orgaentities.service';
import { PopupService, PopupSize } from '@dep/frontend/services/popup.service';
import { TranslationService } from '@dep/frontend/services/translation.service';

@Component({
  selector: 'app-clients-list-insights[row]',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
})
export class ClientsListInsightsComponent implements OnInit {
  @Input() public row!: IOrgaentityTreeItem;
  @Output() public rcpsContentChanged = new EventEmitter<{ orgaentityId: number, playlistName: string }>();

  public readonly enumOneMirrorLicense = OneMirrorLicense;
  public readonly LICENSE_VALIDITY_WARNING_PERIOD = 2592000; // 30 days
  public readonly RoleRight = RoleRight;

  // Metrics
  public isMetricsLoading = false;
  public metrics?: IDeviceMetricItem[];

  // Player Content
  public isContentLoading = false;

  // Licenses
  public licensesExpanded = false;

  private translations: Record<string, string> = {};

  constructor(
    private readonly orgaentitiesService: OrgaentitiesService,
    private readonly logger: NGXLogger,
    private readonly translationService: TranslationService,
    private readonly userHasRightPipe: UserHasRightPipe,
    private readonly deviceSettingsService: DeviceSettingsService,
    private readonly popupService: PopupService,
    private readonly clientIconColorPipe: ClientIconColorPipe,
  ) {
    this.loadTranslations();
  }

  public ngOnInit(): void {
    this.loadClientMetrics();

    this.loadPlayerContent();
  }

  private loadTranslations(): void {
    this.translationService.get([
      'ADMIN_PAGES.CLIENTS.LIST.VERSION',
      'ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE',
      'ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED',
      'ADMIN_PAGES.CLIENTS.LIST.LAST_HEARTBEAT',
      'ADMIN_PAGES.CLIENTS.LIST.CONNECTED',
      'ADMIN_PAGES.CLIENTS.LIST.DISCONNECTED',
      'ADMIN_PAGES.CLIENTS.LIST.NO_ELIGIBLE_PLAYERS',
      'ADMIN_PAGES.CLIENTS.LIST.TO_BASKET_TEXT_SELECT_PLAYER',
      'ADMIN_PAGES.CLIENTS.LIST.ERROR_TOO_MANY_RESULTS',
      'ADMIN_PAGES.CLIENTS.LIST.OK',
      'ADMIN_PAGES.CLIENTS.LIST.CRITICAL',
      'ADMIN_PAGES.CLIENTS.LIST.WARNING',
      'ADMIN_PAGES.CLIENTS.LIST.UNKNOWN',
      'ADMIN_PAGES.CLIENTS.LIST.RUNNING',
      'ADMIN_PAGES.CLIENTS.LIST.UNSTABLE',
      'ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL',
      'ADMIN_PAGES.CLIENTS.LIST.FREE',
    ]).subscribe({
      next: (texts) => {
        this.translations = texts;
      },
    });
  }

  /**
   * Load this client's metrics.
   */
  private async loadClientMetrics(): Promise<void> {
    if (this.row.item.type !== 'CLIENT') {
      return;
    }

    this.isMetricsLoading = true;
    try {
      // Casting to client is possible because we already checked for item type "CLIENT" before.
      const { playername } = (this.row.item as IOrgaentityClient);
      const deviceMetrics = await this.orgaentitiesService.getDeviceMetrics(playername);
      this.metrics = deviceMetrics.items;
    } catch (metricsError) {
      this.logger.error('Getting device metrics failed', metricsError);
    } finally {
      this.isMetricsLoading = false;
    }
  }

  private async loadPlayerContent(): Promise<void> {
    if (
      this.isStageWallPlayer(this.row.item as IOrgaentityClient)
      && await this.userHasRightPipe.transform(RoleRight.CLIENT_CONTENT_CHANGE)
    ) {
      this.getPlayerContent(this.row.item as IOrgaentityClient);
    }
  }

  private isStageWallPlayer(item: IOrgaentityClient): boolean {
    return (item.type === 'CLIENT' && this.orgaentitiesService.getClientTypeAbbreviation(item.playername) === 'wa');
  }

  /**
   * Check Disk Space details from live client metrics 'DiskSpace'
   * Icon color Green | Yellow | Red | Grey
   * @param metricName DiskSpace
   */
  public checkDiskSpace(metricName: string): {
    statusText: string;
    status: string;
    space: string;
    lastUpdated: string;
  } {
    const diskSpace = {
      statusText: this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE'],
      status: 'grey',
      space: '',
      lastUpdated: this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED'] + ': ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
    };

    if (this.metrics) {
      const metric = this.metrics.find((d) => d.name === metricName);
      if (!metric) {
        return diskSpace;
      }
      const index = metric.value.search(/.%/);
      if (index !== -1) {
        let tmpFreeSpace = 0;
        try {
          const value1 = metric.value.charAt(index - 1);
          const value2 = metric.value.charAt(index);
          tmpFreeSpace = Number((!Number.isNaN(Number(value1)) ? value1 : '') + value2);
        } catch (e) {
          // Could not parse and process metric value.
        }

        diskSpace.space = tmpFreeSpace + '% ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.FREE'];

        if (metric.value.search(/DISK OK/g) !== -1) {
          diskSpace.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.OK'] + ': ' + diskSpace.space;
          diskSpace.status = 'green';
        } else if (metric.value.search(/DISK CRITICAL/g) !== -1) {
          diskSpace.status = 'red';
          diskSpace.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.CRITICAL'] + ': ' + diskSpace.space;
        } else if (metric.value.search(/DISK WARNING/g) !== -1) {
          diskSpace.status = 'yellow';
          diskSpace.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.WARNING'] + ': ' + diskSpace.space;
        }
      }
      // Last Updated
      if (metric.updatedAt && metric.updatedAt !== '') {
        diskSpace.lastUpdated = this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED']
          + ': ' + this.translationService.formatDateTime(new Date(metric.updatedAt));
      }
    }
    return diskSpace;
  }

  /**
   * Counts the number of metrics as per status (Ok/Critical/Unknown).
   * Used to display metrics status under "Show more info".
   */
  public countMetricStatus(): string {
    let ok = 0;
    let critical = 0;
    let unknown = 1; // Changes this number according to the number of metrics under "Show more info".

    if (this.metrics) {
      for (const metric of this.metrics) {
        // To add more items to the "Show more" metrics, extend the following
        // line, e. g. `.match(/Sophos|Date|LenovoWarranty/i)`.
        if (metric.name.match(/Sophos/i)) {
          if (metric.value.search(/ok/i) !== -1) {
            ok++;
            unknown--;
          } else if (metric.value.search(/critical/i) !== -1) {
            critical++;
            unknown--;
          }
        }
      }
    }
    return this.translations['ADMIN_PAGES.CLIENTS.LIST.OK'] + ': '
      + ok + ' | ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.CRITICAL'] + ': '
      + critical + ' | ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'] + ': '
      + unknown;
  }

  /**
   * Checks if a provided validity date has expired or has reached an 'Expires soon'
   * period defined in 'LICENSE_VALIDITY_WARNING_PERIOD'.
   *
   * @param validity Validity date string
   * @returns Translation key for validity status displayed in the license details:
   * `ADMIN_PAGES.CLIENTS.LIST.{OK, EXPIRED, EXPIRES_SOON}`. Empty string if no validity
   * date was provided.
   */
  public getValidityStatus(validity: string): string {
    if (!validity) {
      return '';
    }

    if (this.isExpired(validity)) {
      return 'ADMIN_PAGES.CLIENTS.LIST.EXPIRED';
    }

    const dateDiff = new Date(validity).getTime() - new Date().getTime();

    if (dateDiff < this.LICENSE_VALIDITY_WARNING_PERIOD) {
      return 'ADMIN_PAGES.CLIENTS.LIST.EXPIRES_SOON';
    }

    return 'ADMIN_PAGES.CLIENTS.LIST.OK';
  }

  public isExpired(validity: string): boolean {
    return new Date(validity).getTime() < new Date().getTime();
  }

  /**
   * Get player assigned content and add to the `flatTree`.
   */
  public async getPlayerContent(item: IOrgaentityClient): Promise<void> {
    this.isContentLoading = true;
    try {
      this.row.assignedContent = await this.deviceSettingsService.getPlayerContent(item.playername);
      this.logger.debug('Got player content', this.row.assignedContent);

      // A player can have multiple assigned contents.
      // Show model name for the first assigned content.
      if (this.row.assignedContent) {
        this.row.assignedContentModel = getPlaylistInfo(this.row.assignedContent[0])?.modelName;
      }
      this.isContentLoading = false;
    } catch (err) {
      this.isContentLoading = false;
      this.logger.error('Getting player content failed', err);
    }
  }

  private asClient(obj: any): IOrgaentityClient {
    return obj;
  }

  /**
   * Open popup to edit the retailer player name in the RCPS settings.
   *
   * @param row - Organizational entity tree row item
   */
  public openRcpsSettingsRetailerPlayerNamePopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupRCPSSettingsComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
          playername: this.asClient(row.item).playername,
          name: this.asClient(row.item).name,
          clientColor: this.clientIconColorPipe.transform(row.item),
          clientAbbreviation: this.orgaentitiesService.getClientTypeAbbreviation(this.asClient(row.item).playername),
          canViewAndUpdateRetailerPlayerName: 'true',
          canViewAndUpdateHostname: 'false',
          canViewAndUpdateWorkgroup: 'false',
          canViewAndUpdateBandwidthThrottling: 'false',
        },
      },
      {
        onPropertiesSave: (properties: RcpsPlayerPropertiesInput) => {
          // After the changes were successfully saved, update the retailer player name in the cockpit tree.
          row.item.name = properties.name;
        },
      },
    );
  }

  /**
   * Open popup to edit rcps content.
   *
   * @param row - Organizational entity tree row item
   */
  public openRcpsContentEditPopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupRcpsContentEditComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
          name: this.asClient(row.item).name,
          playername: this.asClient(row.item).playername,
          clientColor: this.clientIconColorPipe.transform(row.item),
          clientAbbreviation: this.orgaentitiesService.getClientTypeAbbreviation(this.asClient(row.item).playername),
        },
      },
      {
        onRcpsContentChange: (playlistName: string) => {
          this.rcpsContentChanged.emit({ orgaentityId: row.item.id, playlistName });
        },
      },
    );
  }
}
