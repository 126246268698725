<div class="requests-list content-block content-block-padding content-block-height">
  <h1 *ngIf="!embedded" translate>ADMIN_PAGES.CONTACTS.LIST.CONTACTS</h1>
  <form *ngIf="!embedded" class="content-block-bar row no-gutters" (submit)="search()">
    <div class="col-7 col-md-5">
      <div class="search-input-container">
        <input name="searchTermInput" class="form-control form-control-sm" type="search" [(ngModel)]="searchTermInput" (search)="onSearchTermInputSearch()" placeholder="{{ 'ADMIN_PAGES.CONTACTS.LIST.SEARCH' | translate }}" />
        <img src="assets/icons/search.svg" class="search-input-button" (click)="search()">
      </div>
    </div>
    <div class="col-5 col-md-7 text-right">
      <button type="button" (click)="showNewContactPopup()" class="btn btn-primary btn-sm" translate><span class="d-none d-md-inline-block" translate>ADMIN_PAGES.CONTACTS.LIST.CREATE_CONTACT</span><span class="d-md-none" translate>ADMIN_PAGES.CREATE</span></button>
    </div>
  </form>
  <div class="table-responsive">
    <div *ngIf="isLoading" class="loading loading-center mt-5"></div>
    <table class="table table-sm table-admin table-minheight" [class.isLoading]="isLoading">
      <thead>
        <tr>
          <th class="border-top-0 border-bottom-0 d-table-cell" scope="col" translate>ADMIN_PAGES.CONTACTS.LIST.NAME</th>
          <th class="border-top-0 border-bottom-0 d-table-cell" scope="col" translate>ADMIN_PAGES.CONTACTS.LIST.EMAIL</th>
          <th class="border-top-0 border-bottom-0 d-none d-md-table-cell" scope="col" translate>ADMIN_PAGES.CONTACTS.LIST.VATID</th>
          <th class="border-top-0 border-bottom-0 d-table-cell" scope="col" translate>ADMIN_PAGES.CONTACTS.LIST.CITY</th>
          <th class="border-top-0 border-bottom-0 d-none d-sm-table-cell" scope="col" translate>ADMIN_PAGES.CONTACTS.LIST.ZIP</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="list && list.items.length > 0">
          <ng-container *ngFor="let contact of list.items; let i = index;">
            <tr *ngIf="i >= getLowerPageBound() && i <= getUpperPageBound()">
              <td class="d-table-cell"><a class="no-underline" [routerLink]="['/contacts', contact.id]">{{ contact.name }}</a></td>
              <td class="d-table-cell">{{ contact.mail }}</td>
              <td class="d-none d-md-table-cell">{{ contact.vatId }}</td>
              <td class="d-table-cell">{{ contact.city }}</td>
              <td class="d-none d-sm-table-cell">{{ contact.country }}-{{ contact.zip }}</td>
              <!-- <td class="d-none d-md-table-cell"><span class="badge badge-secondary" translate>ADMIN_PAGES.CONTACTS.LIST.DISABLED</span></td> -->
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(list && list.items.length === 0 && !isLoading) || error">
          <tr>
            <td colspan="8">
              <p *ngIf="!error" translate class="text-center mt-3">ADMIN_PAGES.CONTACTS.LIST.NO_CONTACTS</p>
              <p *ngIf="error" translate class="text-center mt-3 mb-2">ADMIN_PAGES.LOADING_FAILED</p>
              <p *ngIf="error" translate class="text-center table-error-text">{{ error }}</p>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="8">
            <p class="text-right mb-0">
              <app-pagination [list]="this" itemName="{{ 'ADMIN_PAGES.CONTACTS.LIST.PAGES_CONTACT' | translate }}"></app-pagination>
            </p>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
