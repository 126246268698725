<div class="row">
  <div class="col-5">
    <div class="form-group">
      <input
        type="time"
        class="form-control"
        [(ngModel)]="startTime"
        (ngModelChange)="onChangeStartTime($event)"
        name="startTime"
        [class.invalid-setup]="isInvalidActivityCycleSetup"
      />
      <label class="power-label">Power on</label>
    </div>
  </div>
  <div class="col-2 d-flex align-items-center justify-content-center">
    <span style="color: #787878;">to</span>
  </div>
  <div class="col-5">
    <div class="form-group">
      <input
        type="time"
        class="form-control"
        [(ngModel)]="endTime"
        (ngModelChange)="onChangeEndTime($event)"
        name="endTime"
        [class.invalid-setup]="isInvalidActivityCycleSetup"
      />
      <label class="power-label">Power off</label>
    </div>
  </div>
</div>

<!-- Round Slider -->
@if (isSliderVisible) {
  <div class="d-flex justify-content-center">
    <div #slider id="timeRangeSlider" class="time-range-slider"></div>
  </div>
}
