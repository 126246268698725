import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IOrgaentity, RoleRight } from '@dep/common/interfaces';
import { NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qrcode-generator',
  templateUrl: './qrcode-generator.component.html',
  styleUrls: ['./qrcode-generator.component.scss'],
})
export class ConnectedShowroomQrCodeGeneratorComponent implements OnInit {
  public qrCodeImageSource?: string;
  /** Current VIN in the input. */
  public vehicleIdentificationNumber?: string;
  /** VIN of the generated QR code. Used to generate the file name. */
  public generatedVehicleIdentificationNumber?: string;
  public gssn?: string;
  public selectLocations?: NgOption[];
  public isQrGenerating = false;
  public isLoading = false;

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private userService: UserService,
    private orgaentitiesService: OrgaentitiesService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    try {
      const roles = await this.userService.getRoles();

      // 1. Get all roles of the user with right "CONNECTEDSHOWROOM".
      // 2. Get the trees of each role's orgaentities and add them to `selectLocations`.
      await Promise.all(
        roles.items
          .filter((r) => r.role.rights.find((ri) => ri === RoleRight.CONNECTEDSHOWROOM.valueOf()))
          .map((role) => Promise.all(
            role.orgaentities.map(async (oe) => {
              const pathArr = oe.path.split('/');
              pathArr.splice(-2, 2);

              const tree = await this.orgaentitiesService.getClientsTree(oe.id);
              this.selectLocations = [
                ...this.selectLocations || [],
                ...this.treeToSelect(tree.items, pathArr.join('/') + '/'),
              ];
              // Removes duplicate items from array.
              this.selectLocations = this.selectLocations.filter((item, index, self) => self.findIndex((t) => t.value === item.value) === index);
            }),
          )),
      );
    } catch (err) {
      this.logger.error(err);
    }

    this.isLoading = false;
  }

  /**
   * Convert the tree structure to a structure that can be used in an `ng-select` dropdown.
   *
   * ATTENTION: `arr` is a reference that will be modified at each call.
   */
  private treeToSelect(
    tree: Array<IOrgaentity>,
    path = '/',
    level = 0,
    arr: NgOption[] = [],
  ): NgOption[] {
    for (const item of tree) {
      if (item.type === 'LOCATION') {
        arr.push({
          value: item.gssn,
          label: item.name,
          disabled: !item.gssn,
        });
      }

      if (item.children) {
        // eslint-disable-next-line no-param-reassign
        arr = this.treeToSelect(item.children, path + item.id + '/', level + 1, arr);
      }
    }
    return arr;
  }

  /**
   * Generates a QR Code based on the provided VIN and outlet ID.
   * The generated QR Code image is assigned to the 'qrCodeImageSource' property.
   */
  public async generateQrCode(): Promise<void> {
    try {
      // Remove old QR code before entering loading state.
      this.qrCodeImageSource = undefined;
      this.isQrGenerating = true;
      this.generatedVehicleIdentificationNumber = this.vehicleIdentificationNumber;
      this.logger.debug('QR Code: Generate QR Code');

      const response = await lastValueFrom(this.http.post(
        environment.config.miscApi.url + '/vehicleqrcode',
        {
          vin: this.generatedVehicleIdentificationNumber,
          outletId: 'GS' + this.gssn,
        },
        {
          headers: await this.userService.getAuthorizationHeaders(),
          responseType: 'blob',
        },
      ));

      this.logger.debug('Generate QR Code response', response);

      this.qrCodeImageSource = URL.createObjectURL(response);
      this.isQrGenerating = false;
    } catch (err) {
      this.isQrGenerating = false;
      this.toastr.error(
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.GENERATE_FAILED',
        )),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.GENERATE_FAILED_TITLE')),
      );
      this.logger.error('Generate QR Code failed', err);
    }
  }

  /**
   * Creates a temporary `<a>` element, sets its `href` and `download` attributes, triggers a click event on it, and then removes it from the document.
   *
   * @param url - URL of the image
   * @param fileName - Downloaded file name
   */
  public downloadQrCode(url: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
