import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
extendZodWithOpenApi(z);
export const orgaentityBillingAddress = z.object({
    name: z.string().min(1),
    gssn: z.string().min(7).max(8).regex(/^\d+$/),
    street: z.string().min(1),
    city: z.string().min(1),
    zip: z.string().min(1),
    country: z.string().min(2).max(2),
    vatId: z.string().min(1),
    // Custom e-mail regex, because the native zod email() rejects many valid addresses,
    // e. g. such that contain umlauts.
    email: z.string().regex(new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$')),
    phone: z.string().min(1),
});
