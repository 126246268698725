import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import {
  getPlaylistInfo,
  IBrand,
  modelPlaylistMapping,
  getSelectBrandsData,
} from '@dep/frontend/model-playlist-mapping';
import { DeviceSettingsService, IBandwidthSettings } from '@dep/frontend/services/device-settings.service';
import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-rcps-content-edit',
  templateUrl: './rcps-content-edit.component.html',
  styleUrls: ['./rcps-content-edit.component.scss'],
})
export class PopupRcpsContentEditComponent implements OnInit {
  public popup?: IPopup;
  public isLoading = { editRcpsContent: false, throttling: false, playerContent: false };
  public hasError = { editRcpsContent: false, throttling: false };
  public isSuccessSaved = { editRcpsContent: true, throttling: true };
  public isChecked = false;
  public isContentThrottling?: boolean;
  /** Used to custom select mat-tab labels, default is 0 (first tab) */
  public carTypeTabSelectedIndex = 0;

  public isFormDisabled = false;
  public allBrands: IBrand[] = modelPlaylistMapping;
  public clientPlaylistContent?: string[];
  /**
   * Select the first item in the brand list as default.
   * @example - Brands: `Mercedes-Benz, Mercedes-EQ, MAYBACH`
   */
  public selectedBrand?: IBrand = this.allBrands[0];
  public selectedPlaylist = '';
  public selectBrandData: NgOption[] = [];

  constructor(
    public orgaentitiesService: OrgaentitiesService,
    private deviceSettingsService: DeviceSettingsService,
    private logger: NGXLogger,
    private popupService: PopupService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.selectBrandData = Object.entries(getSelectBrandsData()).map((k) => ({
      value: k[0],
      label: k[1],
    }));

    try {
      this.isLoading.playerContent = true;
      this.clientPlaylistContent = await this.deviceSettingsService.getPlayerContent(String(this.popup?.settings.customValues?.playername));
      this.isLoading.playerContent = false;
    } catch (error) {
      this.isLoading.playerContent = false;
      this.logger.error('Getting player content failed', error);
      this.closePopup();
      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.FETCH_FAILED')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.FETCH_FAILED_TITLE')),
      );
    }

    this.logger.debug('Init for playername: ', String(this.popup?.settings.customValues?.playername));
    if (this.clientPlaylistContent) {
      const playlistInfo = getPlaylistInfo(this.clientPlaylistContent[0]);
      if (playlistInfo) {
        this.logger.info('Selected brand:', playlistInfo.brandName);
        this.selectedBrand = this.allBrands.find((brand) => brand.name === playlistInfo.brandName);
        this.carTypeTabSelectedIndex = playlistInfo.tabIndex;
        this.logger.info('Selected content playlist:', this.clientPlaylistContent[0]);
        [this.selectedPlaylist] = this.clientPlaylistContent;
      }
    }

    this.loadDeviceSettings();
  }

  public closePopup(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }

  public async loadDeviceSettings(): Promise<void> {
    this.isLoading.throttling = true;
    this.logger.info('Getting bandwidth limit');
    try {
      const bandwidth = await this.deviceSettingsService.getBandwidth(String(this.popup?.settings.customValues?.playername));
      if (bandwidth === undefined) {
        throw new Error('Bandwidth limit undefined');
      }
      this.logger.info('Getting bandwidth settings');
      this.isContentThrottling = !this.deviceSettingsService.isBandwidthLimitDisabled(bandwidth.setting);
      this.isLoading.throttling = false;
    } catch (error) {
      this.logger.error(error);
      this.isLoading.throttling = false;
      this.hasError.throttling = true;
    }
  }

  public async saveData(from?: UntypedFormGroup): Promise<void> {
    this.logger.info('Saving assigned content data');
    this.logger.info('Playlist selected:', this.selectedPlaylist, 'for player:', this.popup?.settings.customValues?.playername);
    let response: any;
    try {
      this.isLoading.editRcpsContent = true;
      response = await this.deviceSettingsService.replacePlayerIdleContent(String(this.popup?.settings.customValues?.playername), this.selectedPlaylist);
      this.logger.info('Saving assigned content data successfull');
      // On successful save, emit back to clients-list to show updated playlist name for the respective player.
      if (this.popup?.callbacks.onRcpsContentChange) {
        this.popup.callbacks.onRcpsContentChange(this.selectedPlaylist);
      }
      this.isLoading.editRcpsContent = false;
    } catch (e) {
      this.isLoading.editRcpsContent = false;
      this.isSuccessSaved.editRcpsContent = false;
      this.logger.error('Saving assigned content data failed', e);
    }

    if (!this.hasError.throttling && from?.get('contentThrottling')?.touched) {
      try {
        this.isLoading.throttling = true;
        const bandwidth: IBandwidthSettings = this.isContentThrottling
          ? { startTime: '06:00', endTime: '20:00', bandwidthLimit: 10000 }
          : { startTime: '00:00', endTime: '00:01', bandwidthLimit: 1000000000 };
        response = await this.deviceSettingsService.updateBandwidth(String(this.popup?.settings.customValues?.playername), bandwidth);
        this.logger.info('Updating bandwidth success', response);
        this.isLoading.throttling = false;
      } catch (e) {
        this.isLoading.throttling = false;
        this.isSuccessSaved.throttling = false;
        this.logger.error('Updating device settings failed', e);
      }
    } else {
      this.logger.warn('Will not send bandwidth API call', !this.hasError.throttling, from?.get('contentThrottling')?.touched);
    }

    if (!this.isSuccessSaved.throttling || !this.isSuccessSaved.editRcpsContent) {
      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.SAVE_FAILED')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.SAVE_FAILED_TITLE')),
      );
    } else {
      this.closePopup();
      this.toastr.success(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.SAVE_SUCCESS')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.SAVE_SUCCESS_TITLE')),
      );
    }
  }

  public selectBrandRelatedContent(index: number): void {
    this.selectedBrand = this.allBrands[index];
  }

  public selectPlaylist(playlist: string): void {
    this.selectedPlaylist = playlist;
    // TODO: If/when multiple playlist selection is possible, selectedPlaylist should be of type string[]
  }

  public onBrandSelectionChange(brandName: string): void {
    const index = this.allBrands.findIndex((brand) => brand.name === brandName);
    this.selectBrandRelatedContent(index);
  }
}
