import {
  Component,
  EventEmitter,
  AfterViewInit,
  Output,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-time-range-slider',
  templateUrl: './time-range-slider.component.html',
  styleUrls: ['./time-range-slider.component.scss'],
})
export class TimeRangeSliderComponent implements AfterViewInit, OnChanges {
  @Output() public startTimeChange = new EventEmitter<string>();
  @Input() public startTime?: string;

  @Output() public endTimeChange = new EventEmitter<string>();
  @Input() public endTime?: string;

  @Input() public isSliderVisible = true;

  @ViewChild('slider') public slider: any;
  public timeRangeSlider: any;
  public isInvalidActivityCycleSetup = false;

  public ngOnChanges(changes: Partial<SimpleChanges>): void {
    if (this.timeRangeSlider && (changes.startTime || changes.endTime)) {
      const instance = this.timeRangeSlider.data('roundSlider');
      let start: number;
      let end: number;
      if (changes.startTime) {
        start = Number(changes.startTime.currentValue.slice(0, 2)) + (Number(changes.startTime.currentValue.slice(3)) / 60);
        instance.setValue(start, 1);
      }
      if (changes.endTime) {
        end = Number(changes.endTime.currentValue.slice(0, 2)) + (Number(changes.endTime.currentValue.slice(3)) / 60);
        instance.setValue(end, 2);
      }
    }
  }

  /**
   * Handles changes to the start time in a time picker input.
   *
   * The start time must always be smaller than the end time. If it is not, `isInvalidActivityCycleSetup` will be set to `true`.
   *
   * @param newValue - The new start time value from the time input in `HH:mm` format
   */
  public onChangeStartTime(newValue: string): void {
    const startTime = new Date(`1970-01-01T${newValue}:00`);
    const endTime = new Date(`1970-01-01T${this.endTime}:00`);
    this.isInvalidActivityCycleSetup = (startTime >= endTime);

    this.startTimeChange.emit(newValue);
    const instance = this.timeRangeSlider.data('roundSlider');
    const start = Number(newValue.slice(0, 2)) + (Number(newValue.slice(3)) / 60);
    instance.setValue(start, 1);
  }

  /**
   * Handles changes to the end time in a time picker input.
   *
   * The end time must always be greater than the start time. If it is not, `isInvalidActivityCycleSetup` will be set to `true`.
   *
   * @param newValue - The new end time value from the time input in `HH:mm` format
   */
  public onChangeEndTime(newValue: string): void {
    const startTime = new Date(`1970-01-01T${this.startTime}:00`);
    const endTime = new Date(`1970-01-01T${newValue}:00`);
    this.isInvalidActivityCycleSetup = (endTime <= startTime);

    this.endTimeChange.emit(newValue);
    const instance = this.timeRangeSlider.data('roundSlider');
    const end = Number(newValue.slice(0, 2)) + (Number(newValue.slice(3)) / 60);
    instance.setValue(end, 2);
  }

  public ngAfterViewInit(): void {
    if (!this.slider?.nativeElement) {
      return;
    }
    this.timeRangeSlider = $(this.slider.nativeElement);

    /* eslint-disable no-underscore-dangle */
    $.fn.roundSlider.prototype._invertRange = false;

    // this is core functionality to generate the numbers
    // eslint-disable-next-line func-names
    $.fn.roundSlider.prototype.defaults.create = function () {
      const o = this.options;
      const tickInterval = 1;
      for (let i = o.min; i <= o.max - 1; i += tickInterval) {
        const angle = this._valueToAngle(i);
        const numberTag = this._addSeperator(angle, 'rs-custom');
        const number = numberTag.children();
        number.clone().css({
          width: o.width + this._border(),
          'margin-top': this._border(true) / -2,
        }).appendTo(numberTag);
        number.removeClass().addClass('rs-number').html(i).rsRotate(-angle);
      }
    };
    /* eslint-enable no-underscore-dangle */
    this.timeRangeSlider.roundSlider({
      handleSize: '+3',
      radius: 95,
      width: 24,
      sliderType: 'range',
      value: '8,16.5',
      min: 0,
      max: 24,
      step: 0.01,
      startAngle: 90,
      endAngle: 90,
      editableTooltip: false,
      change: (event: any) => {
        let [startTime, endTime] = event.value.split(',');
        startTime = TimeRangeSliderComponent.toTimeFormat(Number(startTime));
        endTime = TimeRangeSliderComponent.toTimeFormat(Number(endTime));
        this.startTimeChange.emit(String(startTime));
        this.endTimeChange.emit(String(endTime));
        this.isInvalidActivityCycleSetup = false;
      },
      tooltipFormat(e: any) {
        const val = e.value;
        return TimeRangeSliderComponent.toTimeFormat(Number(val));
      },
    });
    this.onChangeStartTime(this.startTime || '08:00');
    this.onChangeEndTime(this.endTime || '16:30');
  }

  public static toTimeFormat(val: number): string {
    if (Number.isInteger(val)) {
      let hours: string;

      if (val === 24) {
        hours = '00';
      } else if (Math.floor(val) < 10) {
        hours = '0' + Math.floor(val);
      } else {
        hours = String(Math.floor(val));
      }
      return (hours + ':00');
    }

    const hour = Math.trunc(val);
    const min = val - hour;
    return (
      (hour < 10 ? '0' + hour : hour) + ':' + (Math.round(min * 60) < 10 ? '0' + Math.round(min * 60) : Math.round(min * 60))
    );
  }
}
