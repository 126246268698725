import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { UserService } from '@dep/frontend/services/user.service';
import { UsersService } from '@dep/frontend/services/users.service';

@Component({
  selector: 'app-login-internal',
  templateUrl: './login-internal.component.html',
  styleUrls: ['./login-internal.component.scss'],
})
export class LoginInternalComponent implements OnInit, OnDestroy {
  public username = '';
  public password = '';
  public password2 = '';
  public loginError = '';
  public formDisabled = false;
  public setPasswordMode = false;
  public resetPasswordMode = false;
  public resetPasswordLoading = false;
  public resetPasswordSent = false;
  public newPasswordErrors: string[] = [];
  public newPasswordApiError = '';
  public newPasswordMatch = true;
  public maxPasswordLength = 99; // Cognito's max password length is 99.
  private setPWCognitoUser?: CognitoUser;
  private setPWRequiredAttributes?: any[];
  private activatedRoute$?: Subscription;
  private sessionSub$?: Subscription;
  public passwordStrengthScore = 0;
  public PASSWORD_STRENGTH_CRITERIA: { [key: string]: { min: number; max: number; reg: RegExp; } } = {
    CHARACTER_LENGTH: {
      min: 8,
      max: 24,
      reg: /./g,
    },
    UPPERCASE_CHARACTERS: {
      min: 1,
      max: 8,
      reg: /[A-Z]/g,
    },
    LOWERCASE_CHARACTERS: {
      min: 1,
      max: 8,
      reg: /[a-z]/g,
    },
    SPECIAL_CHARACTERS: {
      min: 1,
      max: 8,
      reg: /[/\\|.,:;"'`!?@#$%^&*[\]{}()<>_+\-=~]/g,
    },
    NUMBER_CHARACTERS: {
      min: 1,
      max: 8,
      reg: /[0-9]/g,
    },
  };

  constructor(
    private logger: NGXLogger,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private usersService: UsersService,
  ) { }

  public ngOnInit(): void {
    // Users should be redirected if they want to access the login page while they
    // are logged in.
    const cognitoUser = this.userService.getCurrentCognitoUser();
    if (cognitoUser) {
      this.onSessionChange(cognitoUser);
      return;
    }

    this.sessionSub$ = this.userService.sessionObservable$.subscribe({
      next: this.onSessionChange.bind(this),
    });

    this.activatedRoute$ = this.activatedRoute.queryParams.subscribe({
      next: (params: { [key: string]: string }) => {
        if (params.login_error) {
          this.loginError = `LOGIN.ERROR.${params.login_error}`;
          // If there was a login_error, the user can be safely signed out
          this.userService.logout();
        }
      },
    });
  }

  public ngOnDestroy(): void {
    this.activatedRoute$?.unsubscribe();
    this.sessionSub$?.unsubscribe();
  }

  /**
   * Callback method that is called when the user session changed.
   *
   * @param cognitoUser - Cognito user object
   */
  private async onSessionChange(cognitoUser: CognitoUser | null): Promise<void> {
    this.logger.debug('userService.sessionObservable triggered', cognitoUser);

    // Re-route to login if cognito does not return a valid user.
    if (!cognitoUser) {
      this.ngZone.run(() => {
        this.router.navigate(['login/internal']);
      });
      return;
    }

    this.formDisabled = true;

    this.logger.debug('userService.sessionObservable ID token', await this.userService.getIdToken());

    this.router.navigate(['/home']);

    this.formDisabled = false;
  }

  public login(): void {
    this.logger.debug('Logging in', this.username);

    this.formDisabled = true;
    this.loginError = '';

    this.userService.login(
      this.username,
      this.password,
      () => { },
      (error: string) => {
        if (error === 'PasswordResetRequiredException') {
          this.setPasswordMode = false;
          this.resetPasswordMode = true;
          this.password = '';
          this.formDisabled = false;
        } else {
          if (error.includes('DisabledUserException')) {
            this.loginError = 'LOGIN.ERROR.USER_DISABLED';
          } else if (error.includes('UnauthorizedLegacyUserException')) {
            this.loginError = 'LOGIN.ERROR.UNAUTHORIZED_LEGACY_USER';
          } else if (error.includes('UserMissingRolesException')) {
            this.loginError = 'LOGIN.ERROR.NO_ROLES';
          } else if (error !== error.toUpperCase()) {
            this.loginError = 'LOGIN.ERROR.DEFAULT';
          } else {
            this.loginError = `LOGIN.ERROR.${error}`;
          }
          this.formDisabled = false;
        }
      },
      (cognitoUser: CognitoUser, requiredAttributes: any[]) => {
        this.formDisabled = false;

        this.setPasswordMode = true;
        this.setPWCognitoUser = cognitoUser;
        this.setPWRequiredAttributes = requiredAttributes;
        this.password = '';
      },
    );
  }

  /**
   * Event handler for ngModelChange on the password fields for setting a new password.
   *
   * @param event - The Angular event passed to the handler.
   * @param checkOnlyMatch - Boolean indicating whether only the match of two passwords have to be checked.
   */
  public onChangeValidatePassword(event: Event, checkOnlyMatch = false): void {
    this.newPasswordMatch = this.password === this.password2;

    if (checkOnlyMatch) {
      return;
    }

    this.newPasswordErrors = this.determinePasswordMinReqErrors(this.password);

    this.passwordStrengthScore = this.determinePasswordStrength(this.password);
  }

  /**
   * Determines the criterias which a given password is not meeting.
   *
   * @param password - The password to check minimum requirements for.
   * @returns errors - The names of all criterias which are not met by the password.
   */
  private determinePasswordMinReqErrors(password: string): string[] {
    const errors: string[] = [];

    if (!password.length) {
      return errors;
    }

    for (const [key, criteria] of Object.entries(this.PASSWORD_STRENGTH_CRITERIA)) {
      const occ = this.findCriteriaOccurrences(key, password);
      if (occ < criteria.min) {
        errors.push(key);
      }
    }

    return errors;
  }

  /**
   * Calculate points for a password rated by configured criterias.
   *
   * @param password - Password to rate.
   * @returns points - The calculated points.
   */
  private determinePasswordStrength(password: string): number {
    let points = 0;
    const criteriaMaxPoints = 1 / Object.keys(this.PASSWORD_STRENGTH_CRITERIA).length;
    for (const [key, criteria] of Object.entries(this.PASSWORD_STRENGTH_CRITERIA)) {
      let occ = this.findCriteriaOccurrences(key, password);
      // occurences below defined minimum are not increasing points
      if (occ < criteria.min) {
        continue;
      }
      // limit points based on occurences to defined maximum
      if (occ > criteria.max) {
        occ = criteria.max;
      }
      // calculate points based on options
      points += (occ / criteria.max) * 100 * criteriaMaxPoints;
    }
    return points;
  }

  /**
   * Find password criteria occurrences by criteria name in given string.
   *
   * @param criteriaName - Name of the criteria to check occurences for.
   * @param str - String to find occurences in.
   * @returns quantity - Quantity of found criteria occurences.
   */
  private findCriteriaOccurrences(criteriaName: string, str: string): number {
    this.logger.debug(`Rating string by regex: ${String(this.PASSWORD_STRENGTH_CRITERIA[criteriaName].reg)}.`);

    const matches = str.match(this.PASSWORD_STRENGTH_CRITERIA[criteriaName].reg);
    this.logger.debug(`Found matches: ${JSON.stringify(matches)}.`);

    return matches !== null ? matches.length : 0;
  }

  public setPassword(): void {
    this.newPasswordErrors = [];
    this.newPasswordApiError = '';
    this.newPasswordMatch = true;

    if (this.password !== this.password2) {
      this.newPasswordMatch = false;
      return;
    }

    if (!this.setPWCognitoUser) {
      throw new Error('Login: setPWCognitoUser not set');
    }

    this.userService.completeNewPasswordChallenge(this.setPWCognitoUser, this.password, this.setPWRequiredAttributes ?? [])
      .then(() => {
        // this.userService.sessionObservable$.next(this.setPWCognitoUser);
        window.location.reload();
      })
      .catch((error: Error) => {
        this.newPasswordApiError = error.message;
        this.logger.error('Login: Setting new password failed', error);
      });
  }

  /**
   * Request a new password for the current `username`. Only works if user is in a passwordless/`PasswordResetRequiredException` state.
   */
  public resetPassword(): void {
    this.resetPasswordLoading = true;
    this.usersService.resetPassword(undefined, this.username).then(() => {
      this.logger.debug('Login: E-mail with new password has been sent');
      this.resetPasswordLoading = false;
      this.resetPasswordSent = true;
    }).catch((error) => {
      const errorMsg = error.message ?? (error.errors && error.errors.length > 0 ? error.errors[0].message : '');

      this.logger.error('Login: Resetting password failed', errorMsg, error);
    });
  }
}
