import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rcpsPlayerPropertiesInput, RcpsPlayerPropertiesInput } from '@dep/common/device-api/types/rcps-player-properties-input.type';
import { IRCPSData } from '@dep/common/interfaces/device-api/rcps-data.interface';
import { IRCPSWorkgroup } from '@dep/common/interfaces/device-api/rcps-workgroup.interface';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';

import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceSettingsService {
  public workgroupsList?: IRCPSWorkgroup[];

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private userService: UserService,
  ) { }

  /**
   * Update RCPS properties (via DEA).
   *
   * @param hostname - Hostname of the player that will be updated
   * @param properties - Properties with the new values
   * @returns RCPS data of the player
   */
  public async updateRcpsProperties(hostname: string, properties: RcpsPlayerPropertiesInput): Promise<IRCPSData> {
    this.logger.debug('Updating RCPS properties', hostname, properties);
    const response = await lastValueFrom(this.http.patch<IRCPSData>(
      `${environment.config.device.apiGateway.url}/devices/${hostname}/rcps`,
      rcpsPlayerPropertiesInput.parse(properties),
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('Updated RCPS properties', response);
    return response;
  }

  /**
   * Get DS player attributes from DEA and connected systems.
   *
   * @param hostname - Player hostname
   * @param attributeName - If set, a certain attribute is queried (see DEA API)
   */
  public async getDeviceAttributes(hostname: string, attributeName?: 'properties' | 'rcps'): Promise<any> {
    this.logger.debug('Getting device attributes', hostname, attributeName);
    const deviceAttributes = await lastValueFrom(this.http.get<any>(
      `${environment.config.device.apiGateway.url}/devices/${hostname}/${attributeName ?? ''}`,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));

    this.logger.debug('Got attributes', deviceAttributes);
    return deviceAttributes;
  }

  public async updateBandwidth(playername: string, bandwidth: IBandwidthSettings): Promise<any> {
    this.logger.debug(`Updating bandwidth of player ${playername} with bandwidth ${JSON.stringify(bandwidth)}`);
    const response = await lastValueFrom(this.http.post(
      `${environment.config.device.apiGateway.url}/devices/${playername}/rcps/BandwidthLimit`,
      bandwidth,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('Updated bandwidth', response);
    return response;
  }

  public async getBandwidth(playername: string): Promise<{
    setting: IBandwidthSettings,
    workgroupName: string,
    defaultLanguageCode: string,
  } | undefined> {
    this.logger.debug('Getting bandwidth for player:', playername);
    const response = await lastValueFrom(this.http.get<IRCPSData | null>(
      `${environment.config.device.apiGateway.url}/devices/${playername}/rcps`,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('Got bandwidth settings', response);
    // default BandwidthLimit setting: 06:00-20:00,10000
    const bandwidthSettings: IBandwidthSettings = { bandwidthLimit: 10000, startTime: '06:00', endTime: '20:00' };
    if (response) {
      if (!response.FTCBandwidthLimit || response.FTCBandwidthLimit === 'Not Set') {
        bandwidthSettings.bandwidthLimit = 10000;
        bandwidthSettings.startTime = '06:00';
        bandwidthSettings.endTime = '22:00';
      } else {
        bandwidthSettings.bandwidthLimit = Number(response.FTCBandwidthLimit.split(',')[1]);
        bandwidthSettings.startTime = String(response.FTCBandwidthLimit.split(',')[0].split('-')[0]);
        bandwidthSettings.endTime = String(response.FTCBandwidthLimit.split(',')[0].split('-')[1]);
      }

      return {
        setting: bandwidthSettings,
        workgroupName: response.workgroupName,
        defaultLanguageCode: response.defaultLanguageCode,
      };
    }
    return undefined;
  }

  public async getAllWorkgroups(): Promise<IRCPSWorkgroup[]> {
    this.logger.debug('Getting all workgroups');
    if (!this.workgroupsList) {
      this.workgroupsList = await lastValueFrom(this.http.get<IRCPSWorkgroup[]>(
        `${environment.config.device.apiGateway.url}/devices/rcps/workgroups/list`,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));
    }

    this.logger.debug('Got workgroups', this.workgroupsList);
    return this.workgroupsList;
  }

  public async updateWorkgroup(playername: string, workgroup: { defaultLanguageCode: string, targetWorkgroupName: string }): Promise<void> {
    this.logger.debug(`Updating workgroup of player ${playername} with workgroup ${JSON.stringify(workgroup)}`);
    const response = await lastValueFrom(this.http.post(
      `${environment.config.device.apiGateway.url}/devices/${playername}/rcps/Workgroup`,
      workgroup,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('Updated workgroup', response);
  }

  public isBandwidthLimitDisabled(bandwidthSettings: IBandwidthSettings): boolean {
    return (
      bandwidthSettings.bandwidthLimit === 1000000000
      && bandwidthSettings.startTime === '00:00'
      && bandwidthSettings.endTime === '00:01'
    );
  }

  /**
   * Get player playlist content.
   *
   * @param hostname - Player hostname
   */
  public async getPlayerContent(hostname: string): Promise<string[] | undefined> {
    this.logger.debug('Getting player content with playername:', hostname);
    const contentItems = await lastValueFrom(this.http.get<string[]>(
      `${environment.config.device.apiGateway.url}/content/${hostname}`,
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    return contentItems;
  }

  /**
   * Replace player idle playlist content.
   *
   * @param hostname - Player hostname
   * @param selectedPlaylist - Playlist content name that will be assigned
   */
  public async replacePlayerIdleContent(hostname: string, selectedPlaylist: string): Promise<any> {
    this.logger.debug('Replacing playlist for player', hostname, selectedPlaylist);
    const response = await lastValueFrom(this.http.put(
      `${environment.config.device.apiGateway.url}/content/${hostname}`,
      {
        content: [selectedPlaylist],
      },
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('Replaced content successfully');
    return response;
  }
}

/**
 * Interface for the RCPS Bandwidth Settings.
 */
export interface IBandwidthSettings {
  startTime: string;
  endTime: string;
  bandwidthLimit: number;
}
