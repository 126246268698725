import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getCountries } from '@dep/common/countries';
import { IContact, RoleRight } from '@dep/common/interfaces';
import { NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ModalComponentOptions } from '@dep/frontend/blocks/info-modal/info-modal.component';
import { ContactModel } from '@dep/frontend/models/contact';
import { UserService } from '@dep/frontend/services/user.service';
import { UsersService } from '@dep/frontend/services/users.service';

@Component({
  selector: 'app-contacts-details',
  templateUrl: './contacts-details.component.html',
  styleUrls: ['./contacts-details.component.scss'],
})
export class ContactsDetailsComponent implements OnInit, OnDestroy {
  public model: ContactModel = this.resetModel();
  public contactId?: number;
  public contacts?: IContact[];
  public canEdit = false;
  private routeParamsSub$?: Subscription;
  public formDisabled = false;
  public isLoading = false;

  public selectCountries: NgOption[] = [];
  public selectedCountry?: string | null;

  public confirmModal?: ModalComponentOptions;

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private ngxTranslate: TranslateService,
    private userService: UserService,
    private usersService: UsersService,
    private toastr: ToastrService,
  ) {
    this.usersService.getContacts().then((contacts) => {
      this.contacts = contacts.items;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.resetModel();
    this.resetModal();

    const countriesCodes = Object.entries(getCountries());
    this.selectCountries = countriesCodes.map((c) => ({
      value: c[0],
      label: c[1],
    }));

    this.routeParamsSub$ = this.route.params.subscribe((params) => {
      this.contactId = params.contactId;
      this.logger.debug('Setting contactId', this.contactId);

      this.resetModel();

      if (this.contactId && this.contactId !== -1) {
        this.isLoading = true;

        this.usersService.getContact(this.contactId).then((contact) => {
          this.model = new ContactModel(
            contact.id,
            contact.name,
            contact.name2,
            contact.street,
            contact.city,
            contact.zip,
            contact.country,
            contact.gssn,
            contact.vatId,
            contact.mail,
            contact.phone,
            contact.requestId,
          );

          this.selectedCountry = this.model.country;
          this.isLoading = false;
        }).catch((err: any) => {
          this.logger.error(`Contact ${String(this.contactId)} could not be loaded`, err);

          const errMsg = err.message ?? (err.errors && err.errors.length ? err.errors[0].message : 'Unknown');

          this.showErrorModal(
            String(this.ngxTranslate.instant('ADMIN_PAGES.INFO_NOTFOUND_TITLE')),
            String(this.ngxTranslate.instant('ADMIN_PAGES.INFO_NOTFOUND_TEXT')) + '<br /><br /><small>' + errMsg + '</small>',
            true,
          );
        });
      }
    });

    this.canEdit = await this.userService.hasRight(RoleRight.CONTACT_UPDATE);
  }

  public ngOnDestroy(): void {
    if (this.routeParamsSub$) {
      this.routeParamsSub$.unsubscribe();
    }
  }

  public isCreateMode(): boolean {
    return this.canEdit && this.contactId === -1;
  }

  private resetModel(): ContactModel {
    this.model = new ContactModel(-1, '', '', '', '', '', '', '', '', '', '', '');
    return this.model;
  }

  public cancel(): void {
    this.formDisabled = false;
    this.router.navigate(['/contacts']);
  }

  public delete(): void {
    this.formDisabled = true;

    this.showConfirmModal(
      String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.CONFIRM_DELETE_TITLE', { name: this.model.name })),
      String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.CONFIRM_DELETE')),
      async () => {
        await this.usersService.deleteContact(this.model.id);
        this.logger.debug('Contact deleted', this.model);
        this.formDisabled = false;
        this.resetModal();
        this.router.navigate(['/contacts']);
      },
    );
  }

  public async update(): Promise<void> {
    this.isLoading = true;

    try {
      await this.usersService.updateContact(this.model);
      this.toastr.success(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.UPDATE_SUCCESS')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.UPDATE_SUCCESS_TITLE')),
      );
      this.router.navigate(['/contacts']);
    } catch (error) {
      this.logger.error('Updating contact failed:', error);
      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.UPDATE_ERROR')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.UPDATE_ERROR_TITLE')),
      );
    } finally {
      this.isLoading = false;
    }
  }

  private showErrorModal(title: string, content = '', goBack = false): void {
    this.confirmModal = {
      dialogType: 'error',
      title,
      content,
      visible: true,
      dismiss: () => {
        this.resetModal();
        if (goBack) {
          this.cancel();
        }
      },
      dismissButtonText: undefined,
      confirm: undefined,
      confirmButtonText: undefined,
    };
  }

  private showConfirmModal(title: string, content: string, onConfirm: () => void): void {
    this.confirmModal = {
      title,
      content,
      visible: true,
      dismiss: () => {
        this.formDisabled = false;
        this.resetModal();
      },
      dismissButtonText: String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.CANCEL')),
      confirm: onConfirm.bind(this),
      confirmButtonText: String(this.ngxTranslate.instant('ADMIN_PAGES.CONTACTS.DETAILS.DELETE')),
    };
  }

  private resetModal(): void {
    this.logger.debug('Modal reset');

    this.confirmModal = {
      visible: false,
      title: '',
      content: '',
      dismiss: () => {},
      dismissButtonText: undefined,
      confirm: undefined,
      confirmButtonText: '',
    };
  }

  public selectedCountryChanged(): void {
    if (this.selectedCountry) {
      this.model.country = this.selectedCountry;
    }
  }
}
