import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, type FormGroup } from '@angular/forms';
import { type IRCPSWorkgroup } from '@dep/common/interfaces/device-api/rcps-workgroup.interface';
import { type NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { DeviceSettingsService, type IBandwidthSettings } from '@dep/frontend/services/device-settings.service';
import { PopupService, type IPopup } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-rcps-settings',
  templateUrl: './rcps-settings.component.html',
  styleUrls: ['./rcps-settings.component.scss'],
})
export class PopupRCPSSettingsComponent implements OnInit {
  public popup?: IPopup;
  public isLoading = {
    retailerPlayerName: false,
    hostname: false,
    throttling: false,
    workgroup: false,
  };

  public hasError = {
    hostname: false,
    throttling:
    false,
    workgroup: false,
  };

  public isSuccessSaved = {
    retailerPlayerName: true,
    hostname: true,
    throttling: true,
    workgroup: true,
  };

  public isSubmitted = false;
  public hasNothingChangedOnSave = false;

  public canViewAndUpdate = {
    retailerPlayerName: false,
    hostname: false,
    workgroup: false,
    bandwidthThrottling: false,
  };

  public isBandwidthSettingsEnabled?: boolean;
  public form?: FormGroup;

  /** Available languages for the current workgroup of the player. */
  public availableLanguagesList?: NgOption[];
  public workgroupsList?: {
    availableLanguages: IRCPSWorkgroup['availableLanguages'];
    value: string;
    label: string;
  }[];

  public bandwidth?: { setting: IBandwidthSettings; workgroupName: string; defaultLanguageCode: string; };

  constructor(
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private deviceSettingsService: DeviceSettingsService,
    private popupService: PopupService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.logger.debug('RcpsBandwidthSettingsModal: Initializing settings popup', this.popup?.settings.customValues);

    this.canViewAndUpdate.retailerPlayerName = (this.popup?.settings.customValues?.canViewAndUpdateRetailerPlayerName === 'true');
    this.canViewAndUpdate.hostname = (this.popup?.settings.customValues?.canViewAndUpdateHostname === 'true');
    this.canViewAndUpdate.workgroup = (this.popup?.settings.customValues?.canViewAndUpdateWorkgroup === 'true');
    this.canViewAndUpdate.bandwidthThrottling = (this.popup?.settings.customValues?.canViewAndUpdateBandwidthThrottling === 'true');

    // Build form group based on shown inputs/sections.
    const formGroup: Record<string, any> = {};
    if (this.canViewAndUpdate.retailerPlayerName) {
      formGroup.retailerPlayerName = [this.popup?.settings.customValues?.name, Validators.required];
    }
    if (this.canViewAndUpdate.hostname) {
      formGroup.hostname = this.formBuilder.control({ value: this.popup?.settings.customValues?.playername, disabled: true }, [Validators.required]);
    }
    if (this.canViewAndUpdate.bandwidthThrottling) {
      formGroup.bandwidth = this.formBuilder.group({
        startTime: [undefined, Validators.required],
        endTime: [undefined, Validators.required],
        bandwidthLimit: [undefined, [Validators.required, Validators.min(0)]],
      });
    }
    if (this.canViewAndUpdate.workgroup) {
      formGroup.workgroup = this.formBuilder.group({
        selectedWorkgroup: [undefined],
        selectedlang: [undefined, Validators.required],
      });
    }
    this.form = this.formBuilder.group(formGroup);

    if (this.popup?.settings.customValues?.playername) {
      this.loadDeviceSettings();
    }
  }

  public async loadDeviceSettings(): Promise<void> {
    this.logger.debug('Loading device settings');
    try {
      this.isLoading = {
        retailerPlayerName: false,
        hostname: false,
        throttling: true,
        workgroup: true,
      };

      // Only load bandwidth settings if the setting is actually needed.
      // (The bandwidth setting is also used to get the `workgroupName` for the workgroups list below.)
      if (this.canViewAndUpdate.bandwidthThrottling || this.canViewAndUpdate.workgroup) {
        this.bandwidth = await this.deviceSettingsService.getBandwidth(this.popup?.settings.customValues?.playername || '');

        if (this.bandwidth === undefined) {
          throw new Error('Bandwidth limit undefined');
        }
        this.isBandwidthSettingsEnabled = !this.deviceSettingsService.isBandwidthLimitDisabled(this.bandwidth.setting);
      }

      // Only load workgroups if the workgroups and languages are actually shown in the UI.
      if (this.canViewAndUpdate.workgroup) {
        this.workgroupsList = (await this.deviceSettingsService.getAllWorkgroups()).map((workgroup) => ({
          availableLanguages: workgroup.availableLanguages,
          value: workgroup.name,
          label: workgroup.name,
        }));

        const workgroup = this.workgroupsList.find((group) => group.label === this.bandwidth?.workgroupName);
        this.availableLanguagesList = workgroup?.availableLanguages.map((lang) => ({
          value: lang.languageCode,
          label: lang.displayName,
        })) || [];
      }

      if (this.canViewAndUpdate.bandwidthThrottling) {
        this.initBandwidthSettings();
      }

      this.isLoading = {
        retailerPlayerName: false,
        hostname: false,
        throttling: false,
        workgroup: false,
      };
    } catch (error: any) {
      this.logger.error('RcpsBandwidthSettingsModal: OnInit error', error?.message);
      this.isLoading = {
        hostname: false,
        throttling: false,
        workgroup: false,
        retailerPlayerName: false,
      };
      this.hasError = { hostname: true, throttling: true, workgroup: true };
    }
  }

  private initBandwidthSettings(): void {
    this.logger.debug('Initializing bandwidth settings');

    this.form?.get('workgroup')?.get('selectedWorkgroup')?.valueChanges.subscribe((workgroupValue: any) => {
      if (workgroupValue) {
        this.form?.get('workgroup')?.get('selectedlang')?.enable();
        this.form?.get('workgroup')?.get('selectedlang')?.reset();
      } else {
        this.form?.get('workgroup')?.get('selectedlang')?.disable();
      }
    });
    const initValues = {
      bandwidth: {
        startTime: this.bandwidth?.setting.startTime,
        endTime: this.bandwidth?.setting.endTime,
        bandwidthLimit: this.bandwidth?.setting.bandwidthLimit,
      },
      workgroup: {
        selectedWorkgroup: this.bandwidth?.workgroupName,
        selectedlang: this.bandwidth?.defaultLanguageCode,
      },
    };
    this.form?.patchValue(initValues);
  }

  // default BandwidthLimit setting: 06:00-20:00,10000
  public resetBandwidth(): void {
    const initValues = {
      bandwidth: {
        startTime: '06:00',
        endTime: '20:00',
        bandwidthLimit: 10000,
      },
    };
    this.form?.patchValue(initValues);
  }

  public async save(): Promise<void> {
    this.logger.debug('RcpsBandwidthSettingsModal: Save', this.form);

    this.isSuccessSaved = {
      retailerPlayerName: true,
      hostname: true,
      throttling: true,
      workgroup: true,
    };
    this.isSubmitted = true;
    this.hasNothingChangedOnSave = false;

    if (!this.popup?.settings.customValues) {
      this.logger.error('Missing popup settings', this.popup);
      return;
    }
    if (!this.form || this.form.invalid) {
      this.logger.error('Invalid form', this.form);
      return;
    }

    let hasNothingChangedOnSave = true;

    // Without a playername, nothing can be saved, since we do not have an identifier for the API in this case.
    if (!this.popup.settings.customValues.playername) {
      this.logger.error('No player name set', this.popup.settings.customValues);
      return;
    }
    const hostname = this.popup.settings.customValues.playername;

    // Update retailer player name if the value changed.
    if (this.canViewAndUpdate.retailerPlayerName) {
      const newRetailerPlayerName = this.form.get('retailerPlayerName')?.value;
      if (newRetailerPlayerName && this.popup.settings.customValues.name !== newRetailerPlayerName) {
        this.logger.debug('Changing retailer player name', newRetailerPlayerName);

        this.isLoading.retailerPlayerName = true;
        try {
          const updatedProperties = await this.deviceSettingsService.updateRcpsProperties(hostname, { name: newRetailerPlayerName });

          this.logger.debug('Retailer player name successfully changed', updatedProperties.name);
          this.toastr.success(
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.RETAILER_PLAYER_NAME.CHANGE_SUCCESS', { hostname })),
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.RETAILER_PLAYER_NAME.CHANGE_SUCCESS_TITLE')),
          );

          if (this.popup.callbacks.onPropertiesSave) {
            this.popup.callbacks.onPropertiesSave(updatedProperties);
          }
        } catch (retailerPlayerNameError) {
          this.logger.error('Updating retailer player name failed', retailerPlayerNameError);
          this.toastr.error(
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.RETAILER_PLAYER_NAME.CHANGE_FAILED', { hostname })),
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.RETAILER_PLAYER_NAME.CHANGE_FAILED_TITLE')),
          );
        } finally {
          this.isLoading.retailerPlayerName = false;
        }
      }
    }

    // Update bandwidth settings if the input was touched.
    if (this.canViewAndUpdate.bandwidthThrottling && this.form.get('bandwidth')?.valid && this.form.get('bandwidth')?.touched) {
      hasNothingChangedOnSave = false;
      this.isLoading.throttling = true;
      try {
        let bandwidthSettings: IBandwidthSettings | undefined;

        // If bandwidth limit is disabled, use disabled default values.
        if (!this.isBandwidthSettingsEnabled) {
          bandwidthSettings = { startTime: '00:00', endTime: '00:01', bandwidthLimit: 1000000000 };
        } else {
          bandwidthSettings = this.form.get('bandwidth')?.value;
        }

        if (bandwidthSettings && bandwidthSettings.startTime && bandwidthSettings.endTime && bandwidthSettings.bandwidthLimit) {
          await this.deviceSettingsService.updateBandwidth(hostname, bandwidthSettings);
          this.isLoading.throttling = false;
        } else {
          throw new Error('Invalid form bandwidth value');
        }
        this.toastr.success(
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_SUCCESS', { name: this.popup.settings.customValues.name })),
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_SUCCESS_TITLE')),
        );
      } catch (error: any) {
        this.logger.error('RcpsBandwidthSettingsModal: Save error updateBandwidth', error?.message);
        this.toastr.error(
          String(this.ngxTranslate.instant(
            'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_FAILED',
            { name: this.popup.settings.customValues.name },
          )) + '<br /><small>' + error.message + '</small>',
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_FAILED_TITLE')),
        );
        this.isLoading.throttling = false;
        this.isSuccessSaved.throttling = false;
      }
    }

    // Update workgroup settings if the input was touched.
    if (this.canViewAndUpdate.workgroup && this.form.get('workgroup')?.valid && this.form.get('workgroup')?.touched) {
      hasNothingChangedOnSave = false;
      this.isLoading.workgroup = true;
      try {
        await this.deviceSettingsService.updateWorkgroup(
          hostname,
          {
            defaultLanguageCode: this.form.get('workgroup')?.get('selectedlang')?.value,
            targetWorkgroupName: this.form.get('workgroup')?.get('selectedWorkgroup')?.value,
          },
        );
        this.toastr.success(
          String(this.ngxTranslate.instant(
            'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_SUCCESS',
            { playername: hostname },
          )),
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_SUCCESS_TITLE')),
        );
        this.isLoading.workgroup = false;
      } catch (error: any) {
        this.logger.error('RcpsBandwidthSettingsModal: Save error updateWorkgroup', error?.message);
        this.toastr.error(
          String(this.ngxTranslate.instant(
            'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_FAILED',
            { playername: hostname },
          )) + '<br /><small>' + error.message + '</small>',
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_FAILED_TITLE')),
        );
        this.isLoading.workgroup = false;
        this.isSuccessSaved.workgroup = false;
      }
    }

    this.hasNothingChangedOnSave = hasNothingChangedOnSave;
    if (this.isSuccessSaved.hostname && this.isSuccessSaved.throttling && this.isSuccessSaved.workgroup) {
      this.closePopup();
    }
  }

  public closePopup(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }
}
