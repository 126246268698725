import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityAll, IUserRoleList, RoleRight } from '@dep/common/interfaces';
import { IForm } from '@dep/common/interfaces/support';

import { ClientHasSupportTicketFormPipe } from './client-has-support-ticket-form.pipe';
import { ClientIsIOTAssignedPipe } from './client-is-iot-assigned.pipe';

import { UserService } from '@dep/frontend/services/user.service';

@Pipe({
  name: 'rowHasContextMenu',
})
export class RowHasContextMenuPipe implements PipeTransform {
  constructor(
    private clientHasSupportTicketFormPipe: ClientHasSupportTicketFormPipe,
    private clientIsIOTAssignedPipe: ClientIsIOTAssignedPipe,
    private userService: UserService,
  ) { }

  /**
   * Determine whether a row has any items in the context menu.
   * (Used to hide the context menu icon if there is nothing to be shown.)
   */
  public transform(orgaentity: IOrgaentityAll, userRoles: IUserRoleList[], supportForms: IForm[]): boolean {
    const canChangeSettings = this.userService.hasRightOnPathSync(userRoles, RoleRight.CLIENT_IOT, orgaentity.path);
    const canReboot = this.userService.hasRightOnPathSync(userRoles, RoleRight.CLIENT_IOT_REBOOT, orgaentity.path);
    const canEditClients = this.userService.hasRightOnPathSync(userRoles, RoleRight.ORGAENTITY_UPDATE, orgaentity.path);
    const canExportClients = this.userService.hasRightOnPathSync(userRoles, RoleRight.ORGAENTITY_READ, orgaentity.path);
    const canCreateOE = this.userService.hasRightOnPathSync(userRoles, RoleRight.ORGAENTITY_CREATE, orgaentity.path);
    const canUpdateCarshowAdapters = this.userService.hasRightOnPathSync(userRoles, RoleRight.CARSHOWADAPTER_UPDATE, orgaentity.path);
    const canReadHOC = this.userService.hasRightOnPathSync(userRoles, RoleRight.HANDOVERCOMPANION_READ, orgaentity.path);
    /** Requires both SUPPORTTICKET_READ right and the corresponding support form to be able to create a support ticket. */
    const canCreateSupportTicket = this.userService.hasRightOnPathSync(userRoles, RoleRight.SUPPORTTICKET_READ, orgaentity.path)
      && this.clientHasSupportTicketFormPipe.transform(orgaentity, supportForms);

    switch (orgaentity.type) {
      case 'CLIENT':
        if (canCreateSupportTicket || canChangeSettings || (this.clientIsIOTAssignedPipe.transform(orgaentity) && canReboot)) {
          return true;
        }
        break;
      case 'CARSHOWADAPTER':
        if (canCreateSupportTicket || canUpdateCarshowAdapters) {
          return true;
        }
        break;
      case 'LOCATION':
        return true;
      case 'HANDOVERCOMPANION':
        if (canReadHOC) {
          return true;
        }
        break;
      case 'COMPANY':
        if (canExportClients) {
          return true;
        }
        break;
      case 'CARMEDIASTICK':
        if (canCreateSupportTicket) {
          return true;
        }
        break;
      case 'MATCHMAKER':
        if (canCreateSupportTicket) {
          return true;
        }
        break;
      case 'SHOWROOMCONNECT':
        if (canCreateSupportTicket) {
          return true;
        }
        break;
      case '':
        if (canExportClients) {
          return true;
        }
        break;
      default:
        break;
    }

    if (canEditClients) {
      return true;
    }

    if (canCreateOE) {
      return true;
    }

    return false;
  }
}
