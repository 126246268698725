import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityTreeItem } from '@dep/common/interfaces';

@Pipe({
  name: 'clientIsRenewable',
})
export class ClientIsRenewablePipe implements PipeTransform {
  public transform(item: IOrgaentityTreeItem['item']): boolean {
    switch (item.type) {
      case 'CARSHOWADAPTER':
      case 'MATCHMAKER':
      case 'HANDOVERCOMPANION':
      case 'CARMEDIASTICK':
      case 'SHOWROOMCONNECT':
        return false;
      case 'CLIENT':
        // Only Digital:Signage is renewable.
        return item.orgaentitySubtypeId === 1;
      default:
        return true;
    }
  }
}
