<h1 [class.mb-4]="mbuiAlerts.length > 0">{{ 'WINDOWS11_UPGRADE_PRODUCT_CHOOSER.' + popup?.settings?.customValues?.title | translate }}</h1>
@for (mbuiAlert of mbuiAlerts; track mbuiAlert) {
  <app-mbui-alert
    variant="outline"
    [type]="mbuiAlert.type"
    [message]="mbuiAlert.message"
  ></app-mbui-alert>
}

<div class="container-fluid mt-4">
  <div class="d-flex">
    @for (product of products; let i = $index; track product) {
      <div class="product">
        <div
          class="product-block"
          [class.product-block-selected]="selectedProduct === product.identifier"
          [class.disabled]="!product.selectedEligiblePlayers"
          (click)="onProductClick(product)"
        >
          <div class="product-block-description min-height">
            <p class="mb-0"><strong>{{ ('WINDOWS11_UPGRADE_PRODUCT_CHOOSER.' + product.identifier + '_TITLE') | translate }}</strong></p>
            <p class="description">{{ 'WINDOWS11_UPGRADE_PRODUCT_CHOOSER.' + product.description | translate }}</p>
          </div>
          <div class="product-block-list">
            @for (selectedEligiblePlayer of product.selectedEligiblePlayers; track selectedEligiblePlayer) {
              <div class="product-block-list-item">{{ selectedEligiblePlayer }}</div>
            }
          </div>
        </div>
      </div>
    }
  </div>

  <!-- Buttons -->
  <div class="footer row">
    <div class="col-12 text-right">
      <p class="mb-0">
        <button
          type="button"
          class="btn btn-secondary mr-3"
          (click)="onPopupClose()"
        >{{ 'WINDOWS11_UPGRADE_PRODUCT_CHOOSER.CANCEL' | translate }}</button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!selectedProduct"
          (click)="onProductSubmit()"
        >{{ 'WINDOWS11_UPGRADE_PRODUCT_CHOOSER.SELECT' | translate }}</button>
      </p>
    </div>
  </div>
</div>
