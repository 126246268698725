import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import { IDeviceMetricItem } from '@dep/common/interfaces';
import { TranslateService } from '@ngx-translate/core';

import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'clientOnlineStatus',
})
export class ClientOnlineStatusPipe implements PipeTransform {
  constructor(
    private readonly ngxTranslate: TranslateService,
    private readonly translationService: TranslationService,
  ) { }

  /**
   * Connection Status determined by live client metrics 'OnlineStatus' value.
   * If 'OnlineStatus' is CRITICAL (status: red) for a player (IOrgaentityTreeItem),
   * all metrics icons for that player are displayed in grey.
   * Icon color G|Y|R
   */
  public transform(metrics?: IDeviceMetricItem[]): { statusText: string; status: string; lastUpdated: string; } {
    const deviceStatus = {
      statusText: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE'),
      status: 'red',
      lastUpdated: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED') + ': ' + this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'),
    };
    if (metrics) {
      const metric = metrics.find((d) => d.name === 'OnlineStatus');
      if (!metric) {
        return deviceStatus;
      }
      if (metric.value.search(/ok/i) !== -1) {
        deviceStatus.status = 'green';
        deviceStatus.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.CONNECTED');
      } else if (metric.value.search(/warning/i) !== -1) {
        deviceStatus.status = 'yellow';
        deviceStatus.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UNSTABLE');
      } else if (metric.value.search(/critical/) !== -1) {
        deviceStatus.statusText = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.DISCONNECTED');
      }
      if (metric.updatedAt && metric.updatedAt !== '') {
        deviceStatus.lastUpdated = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED')
          + ': ' + this.translationService.formatDateTime(new Date(metric.updatedAt));
      }
    }
    return deviceStatus;
  }
}
