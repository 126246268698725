<form class="register row" (ngSubmit)="register()" #form="ngForm">
  <app-help-desk
    class="order-2 order-lg-1 col-10 offset-1 col-sm-6 offset-sm-3 col-lg-2 offset-lg-1 mr-2 mt-3"
  ></app-help-desk>
  <div class="order-1 order-lg-2 col-10 offset-1 col-lg-6 offset-lg-0">
    <!-- <h2 class="text-center mb-0" style="font-size: 16px;">{{ 'REGISTER.SELECT_LANGUAGE' | translate }}</h2> -->
    <div class="d-flex flex-wrap col-12 col-lg-8 m-lg-auto justify-content-center">
      <div class="p-2" *ngFor="let languageCode of allLanguageCodes">
        <span
          class="pointer flag-icon flag-icon-{{ languageCode }}"
          [class.country-selected]="getCountryCodeByLangCode(model.language) === languageCode"
          (click)="changeLanguage(languageCode)"
        ></span>
      </div>
    </div>
    <h1 *ngIf="!ssoReroute" class="mt-4">{{ 'REGISTER.REGISTER' | translate }}</h1>
    <h1 *ngIf="ssoReroute" class="mt-4">{{ 'REGISTER.SSO_REGISTER' | translate }}</h1>
    <div class="row register-section">
      <div class="col-3 text-right label2">
        {{ 'REGISTER.SELECT_COUNTRY' | translate }}*
      </div>
      <p class="col-8 form-control-sm">
        <ng-select
          name="country"
          placeholder="{{ 'REGISTER.SELECT_COUNTRY' | translate }}"
          [items]="selectCountries"
          [(ngModel)]="model.country"
          (change)="onCountrySelectedChanged($event.value)"
          [disabled]="formDisabled || submitLoading || !selectCountries"
          [clearable]="false"
        ></ng-select>
      </p>
      <div class="col-3 text-right label2">
        {{ 'REGISTER.SELECT_SERVICES' | translate }}*
      </div>
      <!-- ng-select for Selecting Services -->
      <p class="col-8">
        <ng-select
          name="accountServices"
          placeholder="{{ 'REGISTER.SELECT_SERVICES' | translate }}"
          [(ngModel)]="model.services"
          [items]="selectServices"
          [multiple]="true"
          [disabled]="formDisabled || submitLoading || !selectServices"
        ></ng-select>
      </p>
    </div>
    <div class="row register-section">
      <div class="col-12">
        <h2>{{ 'REGISTER.PERSONAL_DATA' | translate }}</h2>
        <div class="row">
          <div class="col-3 text-right label">
            {{ 'REGISTER.FIRSTNAME' | translate }}*
          </div>
          <p
            [class.form-row-error]="
              first_name.invalid && first_name.dirty && first_name.touched
            "
            class="col-8"
          >
            <label>
              <input
                name="first_name"
                #first_name="ngModel"
                type="text"
                [(ngModel)]="model.firstname"
                placeholder="{{ 'REGISTER.FIRSTNAME' | translate }}"
                autocomplete="given-name"
                class="form-control form-control-sm"
                [disabled]="formDisabled || submitLoading"
                required
              />
            </label>
          <div class="col-3 text-right label">
            {{ 'REGISTER.LASTNAME' | translate }}*
          </div>
          <p
            [class.form-row-error]="
              last_name.invalid && last_name.dirty && last_name.touched
            "
            class="col-8"
          >
            <label>
              <input
                name="last_name"
                #last_name="ngModel"
                type="text"
                [(ngModel)]="model.lastname"
                placeholder="{{ 'REGISTER.LASTNAME' | translate }}"
                autocomplete="family-name"
                class="form-control form-control-sm"
                [disabled]="formDisabled || submitLoading"
                required
              />
            </label>
          </p>
          <div class="col-3 text-right label">
            {{ 'REGISTER.EMAIL' | translate }}*
          </div>
          <p
          [class.form-row-error]="
            e_mail.invalid && e_mail.dirty && e_mail.touched
          "
          class="col-8"
          >
            <label>
              <input
                name="e_Mail"
                #e_mail="ngModel"
                type="email"
                [(ngModel)]="model.email"
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+$"
                placeholder="{{ 'REGISTER.EMAIL' | translate }}"
                autocomplete="email"
                class="form-control form-control-sm"
                [disabled]="formDisabled || submitLoading"
                required
              />
            </label>
        </p>
        <div class="col-3 text-right label">
          {{ 'REGISTER.PHONE' | translate }}*
        </div>
          <p
            [class.form-row-error]="
              phone_number.invalid && phone_number.dirty && phone_number.touched
            "
            class="col-8"
          >
            <label>
              <!-- Phone number pattern allows inputs like "+49 (170) 1234567", "+49 170 1234567", "004901701234567", "1234567". -->
              <input
                type="tel"
                name="phone_number"
                #phone_number="ngModel"
                [(ngModel)]="model.phone"
                autocomplete="tel"
                class="form-control form-control-sm"
                [disabled]="formDisabled || submitLoading"
                pattern="^(\+)?[0-9\s()]+$"
                required
                placeholder="{{ 'REGISTER.PHONE' | translate }}"
              />
            </label>
          </p>
          <div class="col-3 text-right label">
            {{ 'REGISTER.JOBTITLE' | translate }}*
          </div>
          <p class="col-8">
            <label style="font-weight: 400;">
              <ng-select
                name="jobtitle"
                [(ngModel)]="model.jobtitle"
                [placeholder]="'REGISTER.JOBTITLES.PLACEHOLDER' | translate"
                [clearable]="false"
                [searchable]="false"
              >
                <ng-option *ngFor="let jobtitle of jobtitles" [value]="jobtitle">{{ 'REGISTER.JOBTITLES.' + jobtitle | translate }}</ng-option>
              </ng-select>
            </label>
          </p>
        </div>
        <h2 class="mt-4">{{ 'REGISTER.COMPANY_DATA' | translate }}</h2>
        <div *ngFor="let location of model.locations; let i = index" class="mb-4">
          <div class="row">
            <div class="col-3 text-right label">
              {{ 'REGISTER.COMPANY_NAME' | translate }}*
            </div>
            <p
              *ngIf="model.locations && model.locations[i]"
              [class.form-row-error]="companyAddressLine1.invalid && companyAddressLine1.dirty && companyAddressLine1.touched"
              class="col-8"
            >
              <label>
                <input
                  name="companyAddressLine1_{{i}}"
                  #companyAddressLine1="ngModel"
                  type="text"
                  [(ngModel)]="model.locations[i].companyAddress"
                  placeholder="{{ 'REGISTER.COMPANY_NAME' | translate }}"
                  class="form-control form-control-sm"
                  [disabled]="formDisabled || submitLoading"
                  required
                  maxlength="200"
                />
              </label>
            </p>
            <div class="col-3 text-right label">
              {{ 'REGISTER.ADDRESSLINE2' | translate }} &nbsp;
            </div>
            <p
              class="col-8"
            >
              <label>
                <input
                  name="companyAddress2_{{i}}"
                  #companyAddress2="ngModel"
                  type="text"
                  [(ngModel)]="companyDetails[i].address"
                  placeholder="{{ 'REGISTER.ADDRESSLINE2' | translate }}"
                  class="form-control form-control-sm"
                  [disabled]="formDisabled || submitLoading"
                />
              </label>
            </p>
            <div class="col-3 text-right label">
              {{ 'REGISTER.STREET_STREETNO' | translate }}*
            </div>
            <p
              [class.form-row-error]="companyStreet.invalid && companyStreet.dirty && companyStreet.touched"
              class="col-6"
            >
              <label>
                <input
                  name="companyStreet_{{i}}"
                  #companyStreet="ngModel"
                  type="text"
                  [(ngModel)]="companyDetails[i].street"
                  placeholder="{{ 'REGISTER.STREET' | translate }}"
                  class="form-control form-control-sm"
                  [disabled]="formDisabled || submitLoading"
                  required
                />
              </label>
            </p>
            <p
              [class.form-row-error]="number.invalid && number.dirty && number.touched"
              class="col-2"
            >
            <label>
              <input
                name="number_{{i}}"
                #number="ngModel"
                type="text"
                [(ngModel)]="companyDetails[i].number"
                placeholder="{{ 'REGISTER.NUMBER' | translate }}"
                class="form-control form-control-sm"
                [disabled]="formDisabled || submitLoading"
                required
              />
            </label>
            </p>
            <div class="col-3 text-right label">
              {{ 'REGISTER.ZIPCITY' | translate }}*
            </div>
              <p
                [class.form-row-error]="zip.invalid && zip.dirty && zip.touched"
                class="col-2"
              >
              <label>
                <input
                  name="zip_{{i}}"
                  #zip="ngModel"
                  type="text"
                  [(ngModel)]="companyDetails[i].zip"
                  placeholder="{{ 'REGISTER.ZIP' | translate }}"
                  class="form-control form-control-sm"
                  [disabled]="formDisabled || submitLoading"
                  required
                />
              </label>
              </p>
              <p
                [class.form-row-error]="city.invalid && city.dirty && city.touched"
                class="col-6"
              >
              <label>
                <input
                  name="city_{{i}}"
                  #city="ngModel"
                  type="text"
                  [(ngModel)]="companyDetails[i].city"
                  placeholder="{{ 'REGISTER.CITY' | translate }}"
                  class="form-control form-control-sm"
                  [disabled]="formDisabled || submitLoading"
                  required
                />
              </label>
              </p>
              <div class="col-3 text-right label">
                {{ 'REGISTER.COUNTRY' | translate }}*
              </div>
              <p class="col-8">
                <ng-select
                  name="country"
                  [(ngModel)]="companyDetails[i].country"
                  [items]="selectCountries"
                  [disabled]="formDisabled"
                  placeholder="{{ 'REGISTER.SELECT_LOCATION_COUNTRY' | translate }}"
                  [clearable]="false"
                ></ng-select>
              </p>
            <div class="col-3 text-right label">
              {{ 'REGISTER.LOCATION_ID' | translate }}*
            </div>
            <p *ngIf="model.locations && model.locations[i]" class="col-3" style="margin-top: -2px">
              <ng-select
                name="locationIdType_{{i}}"
                [class.hide-select-arrow]="model.country !== 'DE'"
                [(ngModel)]="model.locations[i].locationIdType"
                [disabled]="model.country !== 'DE' || formDisabled || submitLoading"
                [clearable]="false"
                [searchable]="false"
              >
                <ng-option value="GSSN">GSSN/GS</ng-option>
                <ng-option *ngIf="model.country === 'DE'" value="VF">VF</ng-option>
              </ng-select>
            </p>
            <p
              *ngIf="model.locations && model.locations[i]"
              class="col-5"
              [class.form-row-error]="locationIdValue.invalid && locationIdValue.dirty && locationIdValue.touched"
            >
              <input
                name="locationIdValue_{{i}}"
                #locationIdValue="ngModel"
                type="text"
                [(ngModel)]="model.locations[i].locationIdValue"
                placeholder="{{ 'REGISTER.LOCATION_ID_VALUE' | translate }}"
                title="{{ 'REGISTER.INFO.ENTER_NUMBERS_ONLY' | translate }}"
                class="form-control form-control-sm"
                pattern="{{ model.locations[i].locationIdType === 'GSSN' ? '[0-9]{7,8}' : '[0-9]{5}' }}"
                maxlength="{{ model.locations[i].locationIdType === 'GSSN' ? '8' : '5'}}"
                [disabled]="formDisabled || submitLoading"
                required
              />
              <img class="info-icon" src="assets/icons/info.svg" alt="information">
              <span class="info-icon-text col-12 p-1">{{ 'REGISTER.INFO.ENTER_NUMBERS_ONLY' | translate }}</span>
            </p>
            <div class="col-12">
              <p class="text-right">
                <a *ngIf="i > 0" href="javascript: void(0)" (click)="removeLocation(i)"><img src="assets/icons/delete.svg" class="remove-icon" alt="Remove icon" /> {{ 'REGISTER.REMOVE_LOCATION' | translate }}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p class="text-right"><a href="javascript: void(0)" (click)="addLocation()">+ {{ 'REGISTER.ADD_LOCATION' | translate }}</a></p>
        </div>
      </div>
    </div>

    <div class="row register-section">
      <div class="col-12">
        <p *ngIf="!submitLoading" class="text-center">
          <br />
          <button
            *ngIf="!ssoReroute"
            type="submit"
            class="btn btn-primary"
            [disabled]="formDisabled || submitLoading || !form.valid || !model.country || !model.services || !model.jobtitle || !isLocationIdTypeSet() || !isServicesSet()"
          >
            {{ 'REGISTER.REGISTER' | translate }}
          </button>
          <button
            *ngIf="ssoReroute"
            type="submit"
            class="btn btn-primary"
            [disabled]="formDisabled || submitLoading || !form.valid || !model.country || !model.services || !model.jobtitle || !isLocationIdTypeSet() || !isServicesSet()"
          >
            {{ 'REGISTER.SSO_REGISTER' | translate }}
          </button>
          <br />
          <br />
          <span class="mr-1">{{ 'REGISTER.ALREADY_CREATED' | translate }}</span>
          <a routerLink="/login">{{ 'REGISTER.LOG_IN' | translate }}</a>
        </p>

        <div *ngIf="submitLoading" class="row px-4 pt-3 mt-4">
          <div class="col-12 loading loading-center"></div>
          <div class="col-12 pt-5">
            <p class="text-center">{{ 'REGISTER.PLEASE_WAIT' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<app-info-modal
  *ngIf="infoModal"
  [visible]="infoModal.visible"
  [dialogType]="infoModal.dialogType"
  [title]="infoModal.title"
  [content]="infoModal.content"
  [confirm]="infoModal.confirm ? infoModal.confirm.bind(this) : undefined"
  [confirmButtonText]="infoModal.confirmButtonText || ''"
  [dismiss]="infoModal.dismiss ? infoModal.dismiss.bind(this) : undefined"
  [dismissButtonText]="infoModal.dismissButtonText || ''"
></app-info-modal>
