<div *ngIf="popup && popup.settings.customValues">
  <div class="mb-2">
    <img src="assets/icons/client_{{ popup.settings.customValues.clientAbbreviation }}_{{ popup.settings.customValues.clientColor }}.svg" class="client-icon" [title]="'ID: ' + popup.settings.customValues.orgaentityId "/>
    <span> {{ popup.settings.customValues.playername }} </span>
  </div>
  <div *ngIf="!playerRestarting">
    <p class="text-center">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SYSTEM_RESTART_TEXT' | translate: { playername: popup.settings.customValues.playername } }}</p>
    <div class="text-center pt-3">
      <button type="submit" class="btn btn-primary btn-sm" (click)="restartPlayer()">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.RESTART' | translate }}</button>
      <button type="button" class="btn btn-secondary btn-sm ml-3" (click)="closeOverlay()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
    </div>
  </div>

  <div *ngIf="playerRestarting">
    <p class="text-center mb-0 font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.PLEASE_WAIT' | translate }}</p>
    <p class="text-center">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.PLAYER' | translate }}{{ popup.settings.customValues.playername }}{{ 'ADMIN_PAGES.CLIENTS.DETAILS.RESTARTING' | translate }}</p>
    <div class="loading m-auto"></div>
  </div>
</div>
