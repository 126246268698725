<div *ngIf="visible" class="subscription-modal modal fade show" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header flex-sm-row">
        <h2 class="modal-title">{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.TITLE' | translate }}</h2>
        <span class="mt-3 ml-sm-3" style="color: #9F9F9F;">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOCATION' | translate }}: {{ subscriptionModalLocationName }}</span>
      </div>
      <div *ngIf="isError" class="modal-body text-danger" [innerHtml]="modalErrorMessage"></div>
      <div *ngIf="!isLoading && !isError" class="container-fluid modal-body">
        <!-- Header row -->
        <div class="row mb-2">
          <label class="col-12 col-sm-2 font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.PRODUCT_TYPE' | translate }}</label>
          <label class="col-12 col-sm-1 font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.STATUS' | translate }}</label>
          <label class="col-12 col-sm-9 font-weight-bold pl-sm-5">{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.RECEIVE_EMAIL' | translate }}</label>
        </div>
        <hr>
        <!-- products available for auto alert subscription -->
        <ng-container *ngFor="let product of productsList; let i = index;">
          <div class="row">
            <div class="col-12 col-sm-2 pt-2 align-self-center">{{ (product.productType === 'CLIENT' ? 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.PRODUCT_CLIENT' : product.productType) | translate }}</div>
            <div class="col-12 col-sm-1 pt-2 align-self-center">
              <span *ngIf="product.isSubscribed" class="text-success">{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.ACTIVE' | translate }}</span>
              <span *ngIf="!product.isSubscribed" class="text-warning">{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.INACTIVE' | translate }}</span>
            </div>
            <div class="weekday-picker-container col-12 col-sm-9 col-lg-5 pt-2 pl-sm-5">
              <div class="weekday-picker">
                <input type="checkbox" id="weekday-mon-{{ i }}" value="MO" name="monday_{{ i }}" (change)="onWeekDayChange(i, $event)" [checked]="isChecked(i, 'MO')">
                <label for="weekday-mon-{{ i }}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.MONDAY' | translate }}</label>
                <input type="checkbox" id="weekday-tue-{{ i }}" value="TU" name="tuesday_{{ i }}" (change)="onWeekDayChange(i, $event)" [checked]="isChecked(i, 'TU')">
                <label for="weekday-tue-{{ i }}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.TUESDAY' | translate }}</label>
                <input type="checkbox" id="weekday-wed-{{ i }}" value="WE" name="wednesday_{{ i }}" (change)="onWeekDayChange(i, $event)" [checked]="isChecked(i, 'WE')">
                <label for="weekday-wed-{{ i }}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.WEDNESDAY' | translate }}</label>
                <input type="checkbox" id="weekday-thu-{{ i }}" value="TH" name="thursday_{{ i }}" (change)="onWeekDayChange(i, $event)" [checked]="isChecked(i, 'TH')">
                <label for="weekday-thu-{{ i }}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.THURSDAY' | translate }}</label>
                <input type="checkbox" id="weekday-fri-{{ i }}" value="FR" name="friday_{{ i }}" (change)="onWeekDayChange(i, $event)" [checked]="isChecked(i, 'FR')">
                <label for="weekday-fri-{{ i }}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.FRIDAY' | translate }}</label>
                <input type="checkbox" id="weekday-sat-{{ i }}" value="SA" name="saturday_{{ i }}" (change)="onWeekDayChange(i, $event)" [checked]="isChecked(i, 'SA')">
                <label for="weekday-sat-{{ i }}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SATURDAY' | translate }}</label>
                <input type="checkbox" id="weekday-sun-{{ i }}" value="SU" name="sunday_{{ i }}" (change)="onWeekDayChange(i, $event)" [checked]="isChecked(i, 'SU')">
                <label for="weekday-sun-{{ i }}">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SUNDAY' | translate }}</label>
              </div>
            </div>
            <div class="col-12 col-lg-4 text-right" style="padding-top: 6px;">
              <button
                *ngIf="!product.isSubscribed"
                class="btn btn-sm btn-primary mt-2"
                type="button"
                (click)="createSubscription(product)"
                [disabled]="product.emailSendingDays.length === 0"
              >
                {{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.SUBSCRIBE' | translate }}
              </button>
              <button
                *ngIf="product.isSubscribed"
                class="btn btn-sm btn-primary mt-2"
                type="button"
                (click)="updateSubscription(product)"
                [disabled]="product.emailSendingDays.length === 0"
              >
                {{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.UPDATE' | translate }}
              </button>
              <button
                *ngIf="product.isSubscribed"
                class="btn btn-sm btn-primary mt-2 ml-3"
                type="button"
                (click)="removeSubscription(product)"
              >
                {{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.UNSUBSCRIBE' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <p *ngIf="isLoading" class="text-center"><img src="assets/loading_spinner.gif"/></p>
      <hr class="mb-3">
      <div class="modal-footer border-top-0">
        <button type="button" *ngIf="dismiss" (click)="dismiss()" class="btn btn-primary" [disabled]="isLoading">OK</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="visible" class="modal-backdrop fade show" (click)="dismissModal()"></div>
