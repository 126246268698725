/**
 * Convert empty strings (`""`) to `null`.
 *
 * @param input - Input object
 * @param attributes - If set, only convert the given attributes
 * @returns Modified input object
 */
export function emptyStringsToNull<T extends Record<string, unknown>>(input: T, attributes?: string[]): typeof input {
  const clonedInput = JSON.parse(JSON.stringify(input)) as T;

  const keys = Object.keys(clonedInput);
  for (const key of keys) {
    if (key in clonedInput && (!attributes || attributes.includes(key))) {
      // @ts-expect-error
      clonedInput[key] = clonedInput[key] === '' ? null : clonedInput[key];
    }
  }
  return clonedInput;
}
