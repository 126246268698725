import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IUser, ListResponse, OrderByDirection, RoleRight,
} from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom, Subscription } from 'rxjs';

import { FilterSortMixin, FilterSortMixinInterface, OrderBy } from '@dep/frontend/mixins/filtersort.mixin';
import { PaginationMixin, PaginationMixinInterface } from '@dep/frontend/mixins/pagination.mixin';
import { UserService } from '@dep/frontend/services/user.service';
import { UsersService } from '@dep/frontend/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent
  extends FilterSortMixin(PaginationMixin(class {}))
  implements OnInit, OnDestroy, PaginationMixinInterface, FilterSortMixinInterface {
  public list?: ListResponse<IUser>;
  public readonly RoleRight = RoleRight;
  private routeParamsSub$?: Subscription;

  public showModal = false;
  public onModalDismissFn = (): void => {};

  constructor(
    public logger: NGXLogger,
    public router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private userService: UserService,
    private http: HttpClient,
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.list = {
      items: [],
      count: undefined,
    };

    this.routeParamsSub$ = this.route.params.subscribe({
      next: (params) => {
        this.onParamsUpdate(params);
      },
    });
  }

  public ngOnDestroy(): void {
    this.routeParamsSub$?.unsubscribe();
  }

  public onParamsUpdate(params: any): void {
    this.logger.debug('Setting params', params);

    let nextPage = false;
    let prevPage = false;
    const paramPage = params.page ? Number(params.page) : 1;
    const paramSearchterm = params.searchterm ? params.searchterm : '';

    if (this.searchTerm === paramSearchterm) {
      if (this.getPage() + 1 === paramPage) {
        nextPage = true;
      } else if (this.getPage() - 1 === paramPage) {
        prevPage = true;
      }
    }

    this.searchTermInput = paramSearchterm;
    this.searchTerm = paramSearchterm;

    this.orderBy = params.orderby ? params.orderby : OrderBy.DUMMY;
    this.orderByDirection = params.orderbydirection ? params.orderbydirection : OrderByDirection.ASC;

    this.logger.debug('Going to list page', this.getPage(), paramPage, nextPage, prevPage);
    if (nextPage) {
      this.nextPage();
    } else if (prevPage) {
      this.previousPage();
    } else {
      this.setPage(paramPage);
    }
  }

  public updateNavParams(page?: number): void {
    this.router.navigate(['/users', {
      page: page || this.getPage(),
      searchterm: this.searchTerm,
      orderby: this.orderBy,
      orderbydirection: this.orderByDirection,
    }]);
  }

  /**
   * Set/append requests.
   * @param limit Maximum amount of returned requests
   * @returns Promise with boolean indicating whether Pagination should go to next page or not
   */
  public async loadItems(start: number, limit: number): Promise<ListResponse<IUser>> {
    this.logger.debug(`Loading ${limit} more users...`);
    if (this.searchTerm) {
      this.logger.debug('Search term applied', this.searchTerm);
    }

    const requests = await this.usersService.getUsers(start, limit, typeof this.searchTerm === 'string' ? this.searchTerm.trim() : undefined);
    if (this.setNumberOfResults) {
      this.setNumberOfResults(requests.count);
    }
    return requests;
  }

  /**
   * Download export for users. Default export format is XLSX.
   * Supported format: XLSX, CSV.
   * @param exportFormat - `XLSX` or `CSV`
   */
  public async downloadExport(exportFormat = 'XLSX'): Promise<void> {
    this.logger.debug('Export users in format:', exportFormat);

    this.isLoading = true;
    try {
      await lastValueFrom(this.http.get<void>(
        environment.config.apiGateway.url + '/users/export/' + exportFormat,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));

      this.showExportInfo();
      this.isLoading = false;
    } catch (err) {
      this.logger.error(err);
      this.isLoading = false;
    }
  }

  private showExportInfo(): void {
    this.modalTitle = 'Export initiated';
    this.modalContent = 'When the export is ready, you will receive an e-mail with a temporary download link.';
    this.showModal = true;
    this.onModalDismissFn = () => {
      this.showModal = false;
    };
  }
}
